import { styled } from "~/theme";

import { AIAdsDialogNav } from "../../AIAdsCreationDialog/common";
import { Textarea } from "../../Textarea";

export const CreatorHubCustomScriptHeader = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 24,
});

export const CreatorHubCustomScriptContainer = styled("div", {
  alignItems: "flex-end",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  width: "100%",
  height: "100%",
});

export const CreatorHubCustomScriptInput = styled(Textarea, {
  backgroundColor: "$grey-800",
  height: "100%",
  resize: "none",
  defaultVariants: {
    fullWidth: true,
  },
});

export const CreatorHubCustomScriptActions = styled(AIAdsDialogNav, {
  display: "flex",
  gap: 24,
  width: "100%",
  "&>*": {
    flex: 1,
  },
});
