import { useFeatureFlag } from "feature-flags";
import { useMemo } from "react";

import { AppPlatformConfigMap, useAppPlatform } from "~/context";
import { Project, ProjectEditorType, ProjectFolderType } from "~/database/database.types";

import { ProjectFolderWithStatus } from "../components/ProjectFolderListItem/ProjectFolderListItem.types";
import { ProjectInCreation } from "../components/ProjectsListItem/ProjectsListItem.types";
import { isFolderProject } from "../utils/isFolderProject";

interface UseGetProjectUrlOptions {
  forceEditorType?: ProjectEditorType;
}

export function useGetProjectUrl(options?: UseGetProjectUrlOptions) {
  const { platform } = useAppPlatform();
  const enabledAiEditor = useFeatureFlag("enable_ai_editor");

  const getProjectUrl = useMemo(() => {
    return getProjectUrlForPlatform[platform]({
      enabledAiEditor,
      forceEditorType: options?.forceEditorType,
    });
  }, [platform, enabledAiEditor, options?.forceEditorType]);

  return getProjectUrl;
}

interface GetProjectUrlConfig {
  enabledAiEditor: boolean;
  forceEditorType?: ProjectEditorType;
}

type PlatformRouteMap = { defaultProjectEditor: string; aiEditor: string };

const getFolderTypeFromProjectFolder = (
  projectOrFolder: Project | ProjectFolderWithStatus | ProjectInCreation
) => {
  if (isFolderProject(projectOrFolder)) {
    return projectOrFolder.type;
  } else if ("type" in projectOrFolder && projectOrFolder.type === "ads-in-creation") {
    return "ai-ads";
  }

  return null;
};

const shouldUseAiEditor = (
  config: GetProjectUrlConfig,
  projectOrFolder: Project | ProjectFolderWithStatus | ProjectInCreation
) => {
  return (
    config.enabledAiEditor &&
    config.forceEditorType !== "timeline" &&
    "projectType" in projectOrFolder &&
    projectOrFolder.projectType === "ai-edit" &&
    // if the project was last updated by the ai-editor, we should use the ai-editor
    // otherwise, we should use the default project editor
    projectOrFolder.lastUpdatedByEditorType === "ai-editor"
  );
};

const FOLDER_ROUTE_MAP: Partial<Record<ProjectFolderType, string>> = {
  "ai-ads": "ads",
  "ai-shorts": "shorts",
};

type GetProjectUrlForPlatformFunction = (
  projectOrFolder: Project | ProjectFolderWithStatus | ProjectInCreation
) => string;

const getUrlForProjectOrFolder = (
  config: GetProjectUrlConfig,
  routeMap: PlatformRouteMap,
  combineUrl: (base: string, projectId: string) => string
): GetProjectUrlForPlatformFunction => {
  return (projectOrFolder) => {
    const folderType = getFolderTypeFromProjectFolder(projectOrFolder);

    if (folderType) {
      const folderRoute = FOLDER_ROUTE_MAP[folderType] ?? routeMap.defaultProjectEditor;
      return combineUrl(folderRoute, projectOrFolder.id);
    }

    if ("type" in projectOrFolder && projectOrFolder.type === "in-creation") {
      return "";
    }

    if (shouldUseAiEditor(config, projectOrFolder)) {
      return combineUrl(routeMap.aiEditor, projectOrFolder.id);
    }

    return combineUrl(routeMap.defaultProjectEditor, projectOrFolder.id);
  };
};

type GetProjectUrlForPlatformCreator = (
  config: GetProjectUrlConfig
) => GetProjectUrlForPlatformFunction;

const getProjectUrlForPlatform: AppPlatformConfigMap<GetProjectUrlForPlatformCreator> = {
  web: (config) => {
    return getUrlForProjectOrFolder(
      config,
      {
        // note difference between web and native
        defaultProjectEditor: "projects",
        aiEditor: "projects/ai-editor",
      },
      (base, projectId) => `/${base}/${projectId}`
    );
  },
  native: (config) => {
    return getUrlForProjectOrFolder(
      config,
      {
        // note difference between web and native
        defaultProjectEditor: "project",
        aiEditor: "ai-editor",
      },
      (base, projectId) => {
        const encodedId = encodeURIComponent(projectId);

        return `/${base}?projectId=${encodedId}`;
      }
    );
  },
};
