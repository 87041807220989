import { Root } from "@radix-ui/react-dialog";
import { t } from "i18next";
import { useCallback } from "react";

import {
  AI_ADS_HEADER_BUTTON_CLICK,
  AI_CREATORS_FORM_VIEW,
  AI_CREATOR_START_CLICK,
} from "~/constants/mixpanel.constants";
import { useAnalytics, useTrackEventOnTrue } from "~/hooks/useAnalytics";

import { AIAdsAvatarStep } from "../AIAdsCreationDialog/AIAdsAvatarStep";
import { AIAdsDescriptionStep } from "../AIAdsCreationDialog/AIAdsDescriptionStep";
import { AIAdsScriptStep } from "../AIAdsCreationDialog/AIAdsScriptStep";
import { AIAdsUrlStep } from "../AIAdsCreationDialog/AIAdsUrlStep";
import { CloseButton } from "../BaseDialog";
import AIAds from "../icons/AIAds";
import EditBare from "../icons/EditBare";
import { Sparkles } from "../icons/Sparkles";

import { CreatorHubActions } from "./CreatorHubActions/CreatorHubActions";
import { CreatorHubBase } from "./CreatorHubBase/CreatorHubBase";
import { CreatorHubCustomScript } from "./CreatorHubCustomScript/CreatorHubCustomScript";
import { CreatorHubDialogContent } from "./CreatorHubDialog.styles";
import { CreatorHubActionsItem, CreatorHubDialogProps } from "./CreatorHubDialog.types";
import { CreatorHubPromptStep } from "./CreatorHubPromptStep";

export function CreatorHubDialog(props: CreatorHubDialogProps) {
  const analytics = useAnalytics();

  const CREATOR_HUB_ACTIONS: CreatorHubActionsItem[] = [
    {
      id: "PROMPT_TO_VIDEO",
      icon: <Sparkles />,
      label: t("projects:ai-creators.actions.prompt-to-video"),
      step: "prompt",
      analyticsFlowType: "ai_creator",
    },
    {
      id: "AI_ADS",
      icon: <AIAds />,
      label: t("projects:ai-creators.actions.ai-ads"),
      step: "url",
      analyticsFlowType: "ai_ad",
    },
    {
      id: "CUSTOM",
      icon: <EditBare />,
      label: t("projects:ai-creators.actions.custom"),
      step: "custom",
      analyticsFlowType: "script",
    },
  ];

  useTrackEventOnTrue(props.isOpen, AI_CREATORS_FORM_VIEW);

  const handleClose = useCallback(() => {
    props.onOpenChange?.(false);
  }, []);

  const handleItemClick = (item: CreatorHubActionsItem) => {
    analytics.track(AI_CREATOR_START_CLICK, {
      flow_type: item.analyticsFlowType,
    });

    if (item.id === "AI_ADS") {
      // maintain old mixpanel event for AI Ads
      // TODO(DESK-1634): clean up old ai ads entrypoint
      analytics.track(AI_ADS_HEADER_BUTTON_CLICK);
    }

    props.onNext({
      nextStepFromInitial: item.step,
      flowType: item.analyticsFlowType,
    });
  };

  return (
    <Root open={props.isOpen}>
      <CreatorHubDialogContent step={props.step}>
        <CloseButton onClick={handleClose} />
        {props.step === "initial" && (
          <>
            <CreatorHubBase
              title={t("projects:ai-creators.initial-step.title")}
              description={t("projects:ai-creators.initial-step.description")}
            />

            <CreatorHubActions actionItems={CREATOR_HUB_ACTIONS} onClickItem={handleItemClick} />
          </>
        )}
        {props.step === "url" && (
          <AIAdsUrlStep
            isLoadingNextStep={props.isLoadingNextStep}
            url={props.url}
            onNext={props.onNext}
            onChangeUrl={props.onChangeUrl}
            isCreatorHub
          />
        )}
        {props.step === "info" && (
          <AIAdsDescriptionStep
            isLoadingNextStep={props.isLoadingNextStep}
            onNext={props.onNext}
            onBack={props.onBack}
            title={props.title}
            productName={props.productName}
            description={props.description}
            targetAudience={props.targetAudience}
            languageCode={props.languageCode}
            availableLanguages={props.availableLanguages}
            mediaItems={props.mediaItems}
            onChangeTitle={props.onChangeTitle}
            onChangeProductName={props.onChangeProductName}
            onChangeDescription={props.onChangeDescription}
            onChangeTargetAudience={props.onChangeTargetAudience}
            onChangeLanguageCode={props.onChangeLanguageCode}
            onAddMediaFiles={props.onAddMediaFiles}
            onDeleteMediaItem={props.onDeleteMediaItem}
          />
        )}
        {props.step === "script" && (
          <AIAdsScriptStep
            customScript={props.customScript}
            onChangeCustomScript={props.onChangeCustomScript}
            isLoadingNextStep={props.isLoadingNextStep}
            onNext={props.onNext}
            onBack={props.onBack}
            scripts={props.scripts}
            selectedScriptIndex={props.selectedScriptIndex}
            onSelectScript={props.onSelectScript}
            onChangeScript={props.onChangeScript}
            onRetryScript={props.onRetryScript}
          />
        )}
        {props.step === "avatar" && props.projectType && (
          <AIAdsAvatarStep
            projectType={props.projectType}
            flowType={props.flowType}
            hasBackgroundMusicOption={props.projectType === "ai_ads"}
            previousStep={props.previousStep}
            isLoadingNextStep={props.isLoadingNextStep}
            onNext={props.onNext}
            onBack={props.onBack}
            avatars={props.avatars}
            availableTemplates={props.availableTemplates}
            customTemplates={props.customTemplates}
            selectedTemplateId={props.selectedTemplateId}
            selectedAvatarId={props.selectedAvatarId}
            selectedCreatorVariantId={props.selectedCreatorVariantId}
            aiBgMusicEnabled={props.aiBgMusicEnabled}
            onSelectAvatar={props.onSelectAvatar}
            onSelectTemplate={props.onSelectTemplate}
            onChangeAiBgMusicEnabled={props.onChangeAiBgMusicEnabled}
          />
        )}
        {props.step === "custom" && <CreatorHubCustomScript {...props} />}
        {props.step === "prompt" && <CreatorHubPromptStep {...props} />}
        <CloseButton onClick={handleClose} />
      </CreatorHubDialogContent>
    </Root>
  );
}
