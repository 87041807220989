import { ChangeEvent, forwardRef, ReactElement, useImperativeHandle, useRef } from "react";

import { getVideoFileMetadata, VideoFileMetadata } from "~/utils/getVideoFileMetadata";

import { Button } from "../Button";
import { DropZone } from "../DropZone";
import Import from "../icons/Import";
import * as S from "../SingleFileDropInput/SingleFileDropInput.styles";
import { Text } from "../Text";

import { SingleFileDropInputProps, SubmissionMethod } from "./SingleFileDropInput.types";

/**
 * Creates a component that allows a single file to be submitted by dropping
 * or choosing through a dialog.
 *
 * @param className - the CSS class name to assign to the component.
 * @param onFileSubmitted - callback that will be called when a file is
 * submitted.
 * @constructor
 */
export const SingleFileDropInput = forwardRef<HTMLInputElement, SingleFileDropInputProps>(
  function SingleFileDropInput(
    {
      className,
      onFileSubmitted,
      title,
      description,
      defaultContent,
      hideUploadButton,
      onFileError,
    },
    ref
  ): ReactElement {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleClickChooseFile = () => {
      fileInputRef.current?.click();
    };

    const handleError = (error: unknown) => {
      const serializedError = JSON.stringify(error, Object.getOwnPropertyNames(error));

      const message = !error
        ? "We're unable to upload this file."
        : typeof error === "string"
        ? error
        : typeof error === "object" && "message" in error
        ? `${error.message}`
        : `File drop error: ${serializedError}`;
      onFileError?.(message, error);
    };

    const getMetadata = (file: File): Promise<VideoFileMetadata> =>
      new Promise((resolve, reject) => {
        getVideoFileMetadata(file).then(resolve).catch(reject);
      });

    const handleFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files;
      if (files) {
        const filesArray = Array.from(files || []);
        handleFileSubmission(filesArray, "dialog");
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };

    const handleFileDrop = (files: Array<File>) => {
      handleFileSubmission(files, "drag");
    };

    const handleFileSubmission = async (files: Array<File>, method: SubmissionMethod) => {
      if (!onFileSubmitted) {
        return;
      }

      const metadata = await getMetadata(files[0]).catch((reason) => {
        handleError(reason);
        return null;
      });

      if (!metadata) {
        return;
      }

      onFileSubmitted(files[0] as Array<File> & File, method, metadata);
    };

    useImperativeHandle(
      ref,
      () => {
        return fileInputRef.current as HTMLInputElement;
      },
      [fileInputRef]
    );

    return (
      <DropZone acceptMultiple={false} onDrop={handleFileDrop} className={className}>
        {(isDraggingOver: boolean) => (
          <>
            {/* Always include file input field */}
            <S.SingleFileDropInputInvisibleField
              ref={fileInputRef}
              onChange={handleFileInput}
              multiple={false}
              accept="video/*"
            />

            {!isDraggingOver && defaultContent ? (
              defaultContent
            ) : (
              <S.SingleFileDropInputBox fullHeight draggingOver={isDraggingOver}>
                <S.InputInstructionContainer>
                  <Import />
                  <S.InputInstructionTextContainer>
                    <Text color="grey-150" variant="heading-4">
                      {title}
                    </Text>
                    <Text color="grey-400" variant="body-2">
                      {description}
                    </Text>
                  </S.InputInstructionTextContainer>
                  {!hideUploadButton && (
                    <Button fullWidth onClick={handleClickChooseFile}>
                      Upload a video
                    </Button>
                  )}
                </S.InputInstructionContainer>
              </S.SingleFileDropInputBox>
            )}
          </>
        )}
      </DropZone>
    );
  }
);
