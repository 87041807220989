import { Root } from "@radix-ui/react-dialog";

import { CloseButton } from "~/components/BaseDialog";

import * as S from "./AIEditDialog.styles";
import type { AIEditDialogProps } from "./AIEditDialog.types";
import { AIEditSettingsStep } from "./AIEditSettingsStep/AIEditSettingsStep";
import { AIEditUploadStep } from "./AIEditUploadStep/AIEditUploadStep";

export function AIEditDialog({
  step,
  isOpen,
  handleClose,
  // Step 1
  handleFileChange,
  // Step 2
  videoMetadata,
  currentAIEditStyle,
  onSelectOriginalLanguage,
  onSelectedAIEditStyle,
  handleOnCreateProject,
}: AIEditDialogProps) {
  return (
    <Root open={isOpen}>
      <S.AIEditDialogContents step={step}>
        <CloseButton onClick={handleClose} data-testid="ai-edit-dialog-close-button" />

        {step === "upload" && <AIEditUploadStep onFileSelect={handleFileChange} />}

        {step === "settings" && (
          <AIEditSettingsStep
            videoMetadata={videoMetadata}
            currentAIEditStyle={currentAIEditStyle}
            onSelectedAIEditStyle={onSelectedAIEditStyle}
            onSelectOriginalLanguage={onSelectOriginalLanguage}
            handleOnCreateProject={handleOnCreateProject}
          />
        )}
      </S.AIEditDialogContents>
    </Root>
  );
}
