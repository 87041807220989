import { t } from "i18next";
import { useRef, ChangeEvent, useCallback, useState } from "react";

import { SingleFileDropInputInvisibleField } from "~/components/SingleFileDropInput/SingleFileDropInput.styles";
import { AI_SHORTS_BUTTON_CLICK } from "~/constants/mixpanel.constants";
import { useAnalytics } from "~/hooks/useAnalytics";

import { Button } from "../Button";
import FolderAdd from "../icons/FolderAdd";
import { Text } from "../Text";
import { TextInput } from "../TextInput";

import * as S from "./AIShortsDialog.styles";
import type { AIShortsUploadProps } from "./AIShortsUpload.types";

export const AI_SHORTS_VISUAL_SRC = "https://captions-cdn.xyz/desktop-site/ai-shorts.webp";

/**
 * Modal dialog content for the first step of the AI Shorts flow,
 * with a button for a user to upload their video
 */
export function AIShortsUpload({
  isYoutubeUrlLoading,
  onUrlInputChange,
  onFileSelect,
}: AIShortsUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { track } = useAnalytics();
  const [hasImgError, setHasImgError] = useState(false);

  const handleFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.item(0);
    onFileSelect?.(file!);
  };

  const onClick = useCallback(() => {
    fileInputRef.current?.click();
    track(AI_SHORTS_BUTTON_CLICK);
  }, [fileInputRef, track]);

  return (
    <>
      {!hasImgError && (
        <button
          onClick={() => fileInputRef.current?.click()}
          style={{
            border: "none",
            borderRadius: 8,
            cursor: isYoutubeUrlLoading ? "auto" : "pointer",
          }}
        >
          <img
            src={AI_SHORTS_VISUAL_SRC}
            alt=""
            style={{
              objectFit: "contain",
              width: 773,
              height: "auto",
            }}
            onError={() => {
              setHasImgError(true);
            }}
          />
        </button>
      )}
      <Text variant="heading-3" as="h2">
        {t("projects:ai-shorts.upload.title")}
      </Text>
      <Text variant="body-1" color="grey-500" as="p">
        {t("projects:ai-shorts.upload.subtitle")}
      </Text>
      <S.AIShortsButtonAndInputContainer>
        {!isYoutubeUrlLoading && (
          <Button
            aria-label="Upload video from file"
            data-testid="ai-shorts-dialog-upload-button"
            disabled={isYoutubeUrlLoading}
            withIcon
            size="md"
            variant="primary-plum"
            onClick={onClick}
          >
            <FolderAdd />
            {t("projects:ai-shorts.upload.upload-btn")}
          </Button>
        )}
        <S.AIShortsInputContainer isYoutubeUrlLoading={isYoutubeUrlLoading}>
          <TextInput
            disabled={isYoutubeUrlLoading}
            fullWidth
            width="100%"
            placeholder={t("projects:ai-shorts.upload.input-placeholder")}
            onChange={(event) => onUrlInputChange(event.target.value)}
          />
          {isYoutubeUrlLoading && (
            <S.AIShortsSpinnerContainer>
              <S.AIShortsSpinner />
            </S.AIShortsSpinnerContainer>
          )}
        </S.AIShortsInputContainer>
      </S.AIShortsButtonAndInputContainer>
      <SingleFileDropInputInvisibleField
        disabled={isYoutubeUrlLoading}
        ref={fileInputRef}
        onChange={handleFileInput}
        multiple={false}
        accept="video/*"
        data-testid="ai-shorts-dialog-invisible-file-input"
      />
    </>
  );
}
