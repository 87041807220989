import { keyframes, styled } from "~/theme";

import { Refresh } from "../../icons/Refresh";
import { Textarea } from "../../Textarea";

const rotateClockwise = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(-360deg)",
  },
});

export const PromptStepInputsContainer = styled("div", {
  alignItems: "flex-end",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  width: "100%",
});

export const PromptTextAreaContainer = styled("div", {
  position: "relative",
  width: "100%",
});

export const PromptScriptTextArea = styled(Textarea, {
  backgroundColor: "$grey-800",
  height: "252px",
  resize: "none",
  defaultVariants: {
    fullWidth: true,
  },
});

export const PromptScriptRefreshIcon = styled(Refresh, {
  color: "$white",
  variants: {
    isRefreshing: {
      true: {
        color: "$grey-500",
        animation: `${rotateClockwise} 1s ease 1`,
      },
      false: {},
    },
  },
});
