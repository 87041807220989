import { styled } from "~/theme";

import { IconProps } from "./types";

function Language(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.2489 12C15.2174 14.6558 14.5186 17.3684 13.1523 20.1904"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7355 4C8.08117 9.18064 8.08812 14.3747 10.7563 20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2611 4C15.9154 9.18064 15.9085 14.3747 13.2402 20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0254 16.5601C14.6207 14.4419 9.73059 14.3734 4.99969 16.1096"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7998 7.43994C14.3951 9.55813 9.50501 9.62661 4.77411 7.8904"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="8.5" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
}

export default styled(Language, {
  width: 24,
  height: 24,
  color: "$grey-200",
});
