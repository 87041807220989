import { styled } from "~/theme";

const bannerOverlayGradient = "linear-gradient(180deg, rgba(0, 0, 0, 0) 81.14%, #1C1D21 100%)";

export const CreatorHubBannerSection = styled("div", {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  maxHeight: "calc(100% - 284px)",
  position: "relative",
  backgroundColor: "$pure-black",
});

export const CreatorHubBannerBlurOverlay = styled("div", {
  position: "absolute",
  height: "100%",
  width: "100%",
  background: bannerOverlayGradient,
});

export const CreatorHubBannerVideo = styled("video", {
  height: "100%",
});
