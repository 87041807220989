// Desired aspect ratio for valid videos (width / height)
export const VIDEO_ASPECT_RATIO = 9 / 16;

// Maximum allowable difference from the target aspect ratio
export const VIDEO_ASPECT_RATIO_MAX_DIFF = 0.1;

// Maximum allowed duration for videos in seconds
export const VIDEO_MAX_DURATION = 60;

// Minimum allowed duration for videos in seconds
export const VIDEO_MIN_DURATION = 5;
