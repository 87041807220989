import { BaseDialogContent } from "~/components/BaseDialog";
import { styled } from "~/theme";

export const AIEditDialogContents = styled(BaseDialogContent, {
  boxSizing: "content-box !important",
  maxWidth: "fit-content !important",
  maxHeight: "80vh !important",

  variants: {
    step: {
      upload: {
        padding: "$40 $56 $48 !important",
        gap: "$48 !important",
      },
      settings: {
        maxWidth: "1000px !important",
        padding: "$32 !important",
        gap: "$24 !important",
      },
    },
  },
});
