import { styled } from "~/theme";

import { EntrypointItem, EntrypointItemProps } from "./EntrypointItem";

interface Props {
  items: Array<EntrypointItemProps & { shouldHide?: boolean }>;
}

export function EntrypointHeader({ items }: Props) {
  return (
    <EntrypointContainer>
      {items
        .filter((item) => !item.shouldHide)
        .map(({ shouldHide: _shouldHide, ...item }) => (
          <EntrypointItem key={item.title} {...item} />
        ))}
    </EntrypointContainer>
  );
}

const EntrypointContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
  gap: "$8",
  marginBottom: "$16",
});
