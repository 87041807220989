import { useContext, useState } from "react";

import { AIShortsGenerationContext } from "~/context/AIShortsGenerationContext";

export function useAIShortsDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const { resetWorkflow } = useContext(AIShortsGenerationContext);

  return {
    isOpen,
    open: () => {
      // on open we start a new shorts generation workflow
      resetWorkflow();
      setIsOpen(true);
    },
    close: () => {
      setIsOpen(false);
    },
  };
}
