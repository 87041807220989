import { styled } from "~/theme";

const CONTENT_ABOVE_AND_BELOW_TOTAL_HEIGHT = 245;
const HOME_PADDING = 48;
export const opacityTransitionDuration = 500;

export const HomeLoadingContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: `calc(100% - ${HOME_PADDING}px)`,
  maxHeight: `calc(100vh - ${CONTENT_ABOVE_AND_BELOW_TOTAL_HEIGHT}px)`,
  gap: "24px",
  overflow: "hidden",
  position: "absolute",
  opacity: 0,
  transition: `opacity ${opacityTransitionDuration}ms`,
  backgroundColor: "$grey-950",
  zIndex: 1,
  variants: {
    isLoading: {
      true: {
        opacity: 1,
      },
    },
  },
});

export const SkeletonGroup = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
});
