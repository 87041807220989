import { t } from "i18next";
import { Fragment, useMemo } from "react";

import { getLanguageOptionSearchScore } from "~/modules/project/components/LanguageSelector";
import { LanguageItemLabel } from "~/modules/project/components/LanguageSelector/LanguageItemLabel";
import {
  getLocalizedLanguageLabel,
  getLocalizedLanguageName,
} from "~/modules/project/utils/getLocalizedLanguageName";

import { AI_CREATOR_SCRIPT_MAX_LENGTH, AIAdsCreationDialogProps } from "../../AIAdsCreationDialog";
import { AIAdsDialogNav, AIAdsPageIndicator } from "../../AIAdsCreationDialog/common";
import { Button } from "../../Button";
import { CharacterCounter } from "../../CharacterCounter";
import { ComboBox, ComboBoxGroup, ComboBoxOption } from "../../ComboBox";
import ArrowRight from "../../icons/ArrowRight";
import Language from "../../icons/Language";
import { Spinner } from "../../Spinner";
import { Text } from "../../Text";
import { TextInput } from "../../TextInput";

import * as S from "./CreatorHubPromptStep.styles";

export function CreatorHubPromptStep({
  availableLanguages,
  customScript,
  languageCode,
  isLoadingNextStep,
  prompt,
  onBack,
  onChangeCustomScript,
  onChangeLanguageCode,
  onChangePrompt,
  onGenerateScript,
  onNext,
}: Pick<
  AIAdsCreationDialogProps,
  | "availableLanguages"
  | "isLoadingNextStep"
  | "languageCode"
  | "onBack"
  | "onChangeLanguageCode"
  | "onChangePrompt"
  | "onGenerateScript"
  | "onNext"
  | "prompt"
  | "customScript"
  | "onChangeCustomScript"
>) {
  const normalizedLanguageOptions: (ComboBoxOption | ComboBoxGroup)[] = useMemo(() => {
    return [
      {
        label: "Languages",
        options: availableLanguages
          .filter((item) => item.code !== languageCode)
          .map((item) => ({
            label: (
              <LanguageItemLabel
                displayName={item.display}
                localizedDisplayName={getLocalizedLanguageName(item)}
              />
            ),
            value: item.code,
            getSearchScore: (searchQuery) => getLanguageOptionSearchScore(item, searchQuery),
          })),
      },
    ];
  }, [languageCode, availableLanguages]);

  const customValue = (value?: string) => {
    const language = availableLanguages.find((item) => item.code === value);

    // Should never happen; fallback to default Radix Select behavior
    if (!language) {
      return undefined;
    }

    return getLocalizedLanguageLabel(language);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => onChangePrompt(e.target.value);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return;
    }
    onGenerateScript(prompt);
  };
  const handleNextClick = () => {
    onGenerateScript(prompt);
  };

  const isScriptLoading = isLoadingNextStep;
  const isNextDisabled =
    !customScript || isLoadingNextStep || customScript.length > AI_CREATOR_SCRIPT_MAX_LENGTH;
  const isAvailableLanguageListLoading = isLoadingNextStep || availableLanguages.length === 0;

  return (
    <>
      <Text variant="heading-3" color="grey-200">
        {t("projects:ai-creators.ai-prompt.title")}
      </Text>
      <AIAdsPageIndicator activeStep={1} stepsCount={2} />
      <S.PromptStepInputsContainer>
        <ComboBox
          content={{
            align: "end",
            width: "100%",
          }}
          triggerIcon={<Language />}
          options={normalizedLanguageOptions}
          disabled={isAvailableLanguageListLoading}
          value={languageCode}
          customValue={customValue}
          onValueChange={(value) => onChangeLanguageCode(value)}
          searchPlaceholder={t("common:language-selector.search-placeholder")}
          placeholder={t("common:language-selector.placeholder")}
        />

        <TextInput
          disabled={isScriptLoading}
          value={prompt}
          type="url"
          fullWidth
          variant="subfield-comfortable"
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          placeholder={t("projects:ai-creators.ai-prompt.input-placeholder")}
        >
          <Button
            onlyIcon
            size="sm"
            variant="primary-niagara"
            disabled={!prompt.length || isLoadingNextStep}
            onClick={handleNextClick}
          >
            {customScript && prompt.length ? <S.PromptScriptRefreshIcon /> : <ArrowRight />}
          </Button>
        </TextInput>
      </S.PromptStepInputsContainer>
      <S.PromptTextAreaContainer>
        {(isLoadingNextStep || customScript) && (
          <Fragment>
            <S.PromptScriptTextArea
              maxLength={AI_CREATOR_SCRIPT_MAX_LENGTH}
              disabled={isScriptLoading}
              placeholder={
                isScriptLoading ? "" : t("projects:ai-creators.ai-prompt.textarea-placeholder")
              }
              value={isScriptLoading ? "" : customScript}
              onChange={(e) => onChangeCustomScript(e.target.value)}
            />
            <CharacterCounter maxLength={AI_CREATOR_SCRIPT_MAX_LENGTH} value={customScript ?? ""} />
          </Fragment>
        )}
        {isScriptLoading && <Spinner theme="white" />}
      </S.PromptTextAreaContainer>
      {(isLoadingNextStep || customScript) && (
        <AIAdsDialogNav
          onNext={onNext}
          onBack={onBack}
          isNextDisabled={isNextDisabled}
          nextLabel={t("projects:ai-creators.ai-prompt.next-step-label")}
        />
      )}
    </>
  );
}
