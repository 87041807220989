import { styled } from "@stitches/react";

export const VideoUploadProjectSettingsHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "56px",
});

export const VideoUploadProjectSettingsTitle = styled("div", {
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "600px",
  textAlign: "center",
});

export const VideoUploadProjectSettingsPreviewContent = styled("div", {
  display: "flex",
  columnGap: "32px",
});

export const VideoUploadProjectSettingsPreviewContainer = styled("div", {
  borderRadius: "6px",
  display: "flex",
  maxWidth: "calc(50% - 16px)",
  alignItems: "flex-start",
});

export const VideoUploadProjectSettingsLanguageContainer = styled("div", {
  display: "flex",
  maxWidth: "calc(50% - 16px)",
  minHeight: 550,
});

export const VideoUploadProjectSettingsPreviewCanvas = styled("canvas", {
  objectFit: "contain",
  width: "100%",
  borderRadius: 8,
});
