import { Text } from "~/components";
import { styled } from "~/theme";

export const AIEditUploadStepHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "$12",
});

export const AIEditUploadStepTitle = styled(Text, {
  display: "flex",
  alignItems: "center",
  gap: "$8",

  defaultVariants: {
    variant: "heading-3",
  },
});

export const AIEditUploadStepSubtitle = styled(Text, {
  defaultVariants: {
    color: "grey-500",
    variant: "body-1",
  },
});

export const AIEditUploadStepButtonContainer = styled("div", {
  alignItems: "center",
  borderRadius: "$6",
  display: "flex",
});
