import { ComponentProps, ReactNode } from "react";

import { Skeleton, Text } from "~/components";
import {
  EntrypointLink,
  EntrypointIcon,
  SkeletonContainer,
  EntrypointTextSection,
  EntrypointUpsellSection,
} from "~/components/EntrypointHeader/EntrypointItem.styles";
import { Tooltip } from "~/components/Tooltip";

export interface EntrypointItemProps extends ComponentProps<typeof EntrypointLink> {
  title: string;
  description: ReactNode;
  icon?: ReactNode;
  colors: [string, string];
  disabled?: boolean;
  tooltipText?: string;
  onTooltipView?: () => void;
  onUpsellClick?: () => void;
  isLoading?: boolean;
  upsellTitle?: string;
  upsellDescription?: ReactNode;
  canUpsell?: boolean;
}

export function EntrypointItem({
  title,
  description,
  icon,
  colors,
  href,
  disabled,
  tooltipText,
  onTooltipView,
  onClick,
  onUpsellClick,
  loading,
  upsellTitle,
  upsellDescription,
  canUpsell,
  ...props
}: EntrypointItemProps) {
  const isUpselling = Boolean(canUpsell && upsellTitle && upsellDescription && disabled);
  return (
    <Tooltip tooltipText={tooltipText} unWrapChildren onView={onTooltipView}>
      <EntrypointLink
        disabled={disabled && !isUpselling}
        upselling={isUpselling}
        loading={loading}
        href={href}
        onClick={(e) => {
          if (isUpselling) {
            onUpsellClick?.();
          } else if (disabled) {
            e.preventDefault();
          } else {
            onClick?.(e);
          }
        }}
        {...props}
      >
        <EntrypointIcon
          css={{
            background: `linear-gradient(135deg, ${colors[0]} 14.84%, ${colors[1]} 86.43%)`,
          }}
        >
          {icon}
        </EntrypointIcon>
        <EntrypointTextSection>
          <Text variant="body-1" as="p" color={disabled ? "grey-600" : "grey-100"}>
            {title}
          </Text>
          <Text variant="body-2" color={disabled ? "grey-600" : "grey-500"}>
            {description}
          </Text>
        </EntrypointTextSection>
        {canUpsell && (
          <EntrypointUpsellSection>
            <Text variant="body-1" as="p" color={"grey-100"}>
              {upsellTitle}
            </Text>
            <Text variant="body-2" color={"grey-500"}>
              {upsellDescription}
            </Text>
          </EntrypointUpsellSection>
        )}
        {loading && (
          <SkeletonContainer>
            <Skeleton width="100%" height="100%" css={{ borderRadius: "$16" }} />
          </SkeletonContainer>
        )}
      </EntrypointLink>
    </Tooltip>
  );
}
