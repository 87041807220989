import Link from "next/link";

import { styled } from "~/theme";

export const EntrypointTextSection = styled("div", {
  display: "var(--text-section-display, flex)",
  flexDirection: "column",
});

export const EntrypointUpsellSection = styled("div", {
  display: "var(--upsell-section-display, none)",
  flexDirection: "column",
});

export const EntrypointLink = styled(Link, {
  "--text-section-display": "flex",
  "--upsell-section-display": "none",
  "--icon-filter": "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "$16",
  padding: "$24",
  position: "relative",

  background: "$grey-900",
  borderRadius: "$16",

  "&:hover": {
    background: "$grey-800",
  },

  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
        "--icon-filter": "opacity(0.75) brightness(0.5)",
        "&:hover": {
          backgroundColor: "$grey-900",
        },
      },
    },
    upselling: {
      true: {
        "--icon-filter": "opacity(0.75) brightness(0.5)",
        "&:hover": {
          "--text-section-display": "none",
          "--upsell-section-display": "flex",
          backgroundColor: "$grey-900",
          "--icon-filter": "brightness(0.5)",
        },
      },
    },
    loading: {
      true: {
        pointerEvents: "none",
      },
    },
  },
});

export const EntrypointIcon = styled("div", {
  display: "flex",
  flexShrink: 0,
  alignItems: "center",
  justifyContent: "center",

  width: "40px",
  height: "40px",
  borderRadius: "100%",
  boxShadow: "$highlight-1",

  filter: "var(--icon-filter)",

  "& > svg": {
    color: "white",
  },

  "@bp2": {
    display: "none",
  },
});

export const SkeletonContainer = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
});
