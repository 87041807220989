import { styled } from "~/theme";

export const CreatorHubActionsContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  width: "100%",
  gap: "$12",
  padding: "0 $24",
  "@bp1": {
    flexDirection: "column",
  },
});

export const CreatorHubActionsItem = styled("button", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "$grey-800",
  cursor: "pointer",
  borderRadius: "16px",
  flexBasis: 1,
  flexGrow: 1,
  height: 148,
  justifyContent: "center",
  gap: 12,
  transition: "background-color 200ms",

  "@bp1": {
    width: "100%",
  },

  "> svg": {
    width: 36,
    height: 36,
    color: "$grey-200",
  },

  "&:hover, &:focus-visible": {
    backgroundColor: "$grey-700",
    outline: "none",
  },
  "&:active": {
    backgroundColor: "$grey-800",
  },
  "&:disabled": {
    cursor: "not-allowed",
    "&:hover": {
      backgroundColor: "$grey-800",
    },
  },
});
