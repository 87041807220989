import { styled } from "~/theme";

import { Spinner } from "../Spinner";

export const AIShortsButtonAndInputContainer = styled("div", {
  alignItems: "center",
  borderRadius: "16px",
  display: "flex",
  gap: "10px",
});

export const AIShortsInputContainer = styled("div", {
  alignItems: "center",
  borderRadius: "6px",
  display: "flex",
  backgroundColor: "$grey-800",
  padding: "0 16px 0 8px",
  width: "220px",
  variants: {
    isYoutubeUrlLoading: {
      true: {
        width: "442px",
      },
    },
  },
});

export const AIShortsSpinnerContainer = styled("div", {
  alignItems: "center",
  display: "flex",
});

export const AIShortsSpinner = styled(Spinner, {
  color: "inherit",
});
AIShortsSpinner.defaultProps = { inline: true, theme: "white" };
