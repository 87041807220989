import { ComponentProps } from "react";

import { styled } from "~/theme";
import { unboundedFont } from "~/theme/fonts";
import { hexToColorCss } from "~/utils/colorConversion/hexToColor";
import { ColorCssType } from "~/utils/colorConversion/types";

export function AITwinName({
  name,
  color,
  ...props
}: {
  name: string;
  color: string | ColorCssType;
} & Omit<ComponentProps<typeof Name>, "color">) {
  const colorObj = typeof color === "string" ? hexToColorCss(color) : color;

  const textColor = `rgb(${colorObj.red},${colorObj.green},${colorObj.blue})`;
  const textShadow = `rgba(${colorObj.red},${colorObj.green},${colorObj.blue},${0.7})`;

  return (
    <Name
      style={{
        color: textColor,
        textShadow: `${textShadow} 0px 0 8px`,
      }}
      {...props}
    >
      {name}
    </Name>
  );
}

const Name = styled("span", {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontSize: "44px",
  fontWeight: "bold",
  display: "inherit",
  fontFamily: unboundedFont.style.fontFamily,

  variants: {
    size: {
      sm: {
        fontSize: "16px",
      },
      md: {
        fontSize: "26px",
      },
      lg: {
        fontSize: "36px",
      },
      xl: {
        fontSize: "44px",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});
