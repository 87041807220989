import { Text } from "../../Text";

import { CreatorHubActionsContainer, CreatorHubActionsItem } from "./CreatorHubActions.style";
import { CreatorHubActionsProps } from "./CreatorHubActions.types";

export function CreatorHubActions({ actionItems, onClickItem }: CreatorHubActionsProps) {
  return (
    <CreatorHubActionsContainer>
      {actionItems.map((actionItem) => (
        <CreatorHubActionsItem key={actionItem.id} onClick={() => onClickItem(actionItem)}>
          {actionItem.icon}
          <Text variant="heading-4-medium" color="grey-200">
            {actionItem.label}
          </Text>
        </CreatorHubActionsItem>
      ))}
    </CreatorHubActionsContainer>
  );
}
