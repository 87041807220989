import { t } from "i18next";
import { MouseEvent, useEffect, useMemo, useState } from "react";

import { Text } from "~/components";
import Close from "~/components/icons/Close";
import DeleteIcon from "~/components/icons/Delete";
import ErrorCircle from "~/components/icons/ErrorCircle";
import { Spinner } from "~/components/Spinner";
import { Tooltip } from "~/components/Tooltip";
import { EffectsRepository } from "~/database/repo/EffectsRepository";
import { isProjectDubbed, isProjectTranslated } from "~/utils/projectInfo";
import { getClipsDurationBeforePosition } from "~/utils/videoClips";

import { convertToTuple } from "../../../project/hooks/useClipSplitting";
import { ThumbOverlay } from "../ProjectFolderListItem/ProjectFolderListItem.styles";
import { ProjectListItemThumbnail } from "../ProjectListItemThumbnail";
import { ProjectsListItemMetadata } from "../ProjectsListItemMetadata";

import * as S from "./ProjectsListItem.styles";
import { ProjectsListItemProps } from "./ProjectsListItem.types";

const secondsToMinutes = (seconds: number) => Math.trunc(seconds / 60);

const formatDuration = (duration: number) => {
  const minutes = secondsToMinutes(duration);
  const seconds = Math.trunc(duration % 60);

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

function ProjectsListItem({
  project,
  onRemove,
  videoFileMetadata,
}: Readonly<ProjectsListItemProps>) {
  const {
    id,
    title,
    creationStage,
    sourceVideoMetadata,
    sourceFileLanguageCode,
    targetLanguageCode,
    translateAudioEnabled,
    projectType,
  } = project;

  const [duration, setDuration] = useState<number>(sourceVideoMetadata.duration);

  const handleRemove = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    event.preventDefault();
    if (onRemove) {
      onRemove(id);
    }
  };

  const isError = creationStage === "error";

  const tagLabel = useMemo(() => {
    const hasDubbingLabel = isProjectDubbed({
      targetLanguageCode,
      sourceFileLanguageCode,
      translateAudioEnabled,
    });
    if (projectType === "ai-edit") {
      return <S.TagLabel>{t("projects:tag-label.ai-edit")}</S.TagLabel>;
    }
    if (hasDubbingLabel) {
      return <S.TagLabel>{t("projects:tag-label.ai-dubbing")}</S.TagLabel>;
    }

    const hasTranslationLabel = isProjectTranslated({ targetLanguageCode, sourceFileLanguageCode });
    if (hasTranslationLabel) {
      return <S.TagLabel>{t("projects:tag-label.ai-translate")}</S.TagLabel>;
    }

    return null;
  }, [targetLanguageCode, sourceFileLanguageCode, translateAudioEnabled, projectType]);

  useEffect(() => {
    const getDurationWithDeletedClips = async () => {
      if (!project.effectsId) {
        return;
      }
      const effects = await EffectsRepository.getEffects(project.effectsId);
      if (!effects?.clips) {
        return;
      }
      const deletedClips = effects.clips.filter((clip) => clip.deleted).map(convertToTuple);
      const deletedClipsDuration = getClipsDurationBeforePosition(
        deletedClips,
        sourceVideoMetadata.duration,
        true
      );
      setDuration(sourceVideoMetadata.duration - deletedClipsDuration);
    };

    getDurationWithDeletedClips();
  }, [project.id, sourceVideoMetadata.duration]);

  const isAdsInCreation = project?.type === "ads-in-creation";
  const isInCreation = project?.type === "in-creation" || isAdsInCreation;

  const creationSuffix = useMemo(() => {
    if (isAdsInCreation) {
      return isError
        ? t("projects:project-list-item.creation-suffix.ads-in-creation.error")
        : t("projects:project-list-item.creation-suffix.ads-in-creation.creating");
    }
    return isError
      ? t("projects:project-list-item.creation-suffix.in-creation.error")
      : t("projects:project-list-item.creation-suffix.in-creation.creating");
  }, [isAdsInCreation, isError]);

  if (isInCreation) {
    let progressText = `${(project.progress ?? 0).toFixed(0)}% · ${creationSuffix}`;
    if (isAdsInCreation && project.estimatedTime != null) {
      progressText = t("projects:project-list-item.progress-text.queued");
      if (project.estimatedTime) {
        const minutes = secondsToMinutes(project.estimatedTime);
        progressText += t("projects:project-list-item.progress-text.estimated-time", {
          minutes,
          plural: minutes === 1 ? "" : "s",
        });
      } else {
        progressText += t("projects:project-list-item.progress-text.estimating-time");
      }
    }
    return (
      <S.ProjectsListItemWrapper inCreation isAdsInCreation={isAdsInCreation}>
        <S.ThumbTitleAndDurationContainer>
          <S.ThumbBox>
            <ProjectListItemThumbnail
              title={title}
              thumbnailPath={project.thumbnailPath}
              videoFileMetadata={videoFileMetadata}
            />
            {isError ? (
              <ThumbOverlay variant="error">
                <ErrorCircle />
              </ThumbOverlay>
            ) : (
              <ThumbOverlay variant="loading">
                <Spinner theme="white" inline />
              </ThumbOverlay>
            )}
          </S.ThumbBox>
          <S.TitleAndDurationContainer>
            <S.FileName title={title} translate="no">
              {title}
            </S.FileName>
            <Text color="grey-500" variant="body-2">
              {progressText}
            </Text>
          </S.TitleAndDurationContainer>
        </S.ThumbTitleAndDurationContainer>
        {!isAdsInCreation && <ProjectsListItemMetadata sourceVideoMetadata={sourceVideoMetadata} />}
        <S.LabelAndDeleteContainer>
          {tagLabel}
          <Tooltip tooltipText={t("projects:project-list-item.cancel.tooltip")}>
            <S.Remove
              aria-label={t("projects:project-list-item.cancel.aria-label")}
              onClick={(e) => handleRemove(e, id)}
            >
              <Close />
            </S.Remove>
          </Tooltip>
        </S.LabelAndDeleteContainer>
      </S.ProjectsListItemWrapper>
    );
  }

  return (
    <S.ProjectsListItemWrapper>
      <S.ThumbTitleAndDurationContainer>
        <S.ThumbBox>
          <ProjectListItemThumbnail
            title={title}
            thumbnailPath={project.thumbnailPath}
            videoFileMetadata={videoFileMetadata}
          />
        </S.ThumbBox>
        <S.TitleAndDurationContainer>
          <S.FileName title={title} translate="no">
            {title}
          </S.FileName>
          <Text color="grey-500" variant="body-2">
            {formatDuration(duration || 0)}
          </Text>
        </S.TitleAndDurationContainer>
      </S.ThumbTitleAndDurationContainer>
      <ProjectsListItemMetadata sourceVideoMetadata={sourceVideoMetadata} />
      <S.LabelAndDeleteContainer>
        {tagLabel}
        <Tooltip tooltipText={t("projects:project-list-item.delete.tooltip")}>
          <S.Remove
            aria-label={t("projects:project-list-item.delete.aria-label")}
            onClick={(e) => handleRemove(e, id)}
          >
            <DeleteIcon />
          </S.Remove>
        </Tooltip>
      </S.LabelAndDeleteContainer>
    </S.ProjectsListItemWrapper>
  );
}

export default ProjectsListItem;
