import { t } from "i18next";

import { Text } from "~/components";
import { formatFileSize } from "~/utils/file";

import { ProjectListItemMetadataProps } from "./ProjectsListItemMetadata.types";

const roundFps = (fps: number) => Math.round(fps * 100) / 100;

function ProjectsListItemMetadata({ sourceVideoMetadata }: Readonly<ProjectListItemMetadataProps>) {
  return (
    <>
      {sourceVideoMetadata.width ? (
        <Text color="grey-500" variant="body-2">
          {t("projects:project-list-item.metadata.dimensions")} |{" "}
          <Text color="grey-200" variant="body-2">
            {sourceVideoMetadata.width}x{sourceVideoMetadata.height}
          </Text>
        </Text>
      ) : (
        <Text />
      )}
      {sourceVideoMetadata.size ? (
        <Text color="grey-500" variant="body-2">
          {t("projects:project-list-item.metadata.size")} |{" "}
          <Text color="grey-200" variant="body-2">
            {formatFileSize(sourceVideoMetadata.size)}
          </Text>
        </Text>
      ) : (
        <Text />
      )}
      {sourceVideoMetadata.fps ? (
        <Text color="grey-500" variant="body-2">
          {t("projects:project-list-item.metadata.frame-rate")} |{" "}
          <Text color="grey-200" variant="body-2">
            {roundFps(sourceVideoMetadata.fps)} fps
          </Text>
        </Text>
      ) : (
        <Text />
      )}
    </>
  );
}

export default ProjectsListItemMetadata;
