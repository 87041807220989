import { DialogProps, Root } from "@radix-ui/react-dialog";
import { useContext, useEffect } from "react";

import { BaseDialogContent, CloseButton } from "~/components/BaseDialog";
import {
  AI_SHORTS_CREATION_STATUS,
  AI_SHORTS_CREATION_STATUS_SOURCE,
} from "~/constants/mixpanel.constants";
import { AIShortsGenerationContext } from "~/context/AIShortsGenerationContext";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useUploadYoutube } from "~/hooks/useUploadYoutube";

import { AIShortsSettings } from "./AIShortsSettings";
import type { AIShortsConfig } from "./AIShortsSettings.types";
import { AIShortsUpload } from "./AIShortsUpload";

interface AIShortsDialogProps extends DialogProps {
  uploadYoutube: ReturnType<typeof useUploadYoutube>;
}

export function AIShortsDialog({ uploadYoutube, onOpenChange, ...props }: AIShortsDialogProps) {
  const { track } = useAnalytics();

  const { setFile, setGenInfo, file, resetWorkflow, progressStage, projectId } =
    useContext(AIShortsGenerationContext);

  const handleOnClose = () => {
    onOpenChange?.(false);

    // on close if a file has been set we need to reset the context workflow
    if (file) {
      track(AI_SHORTS_CREATION_STATUS, {
        status: "cancel",
        ai_shorts_group_id: projectId,
        cancel_stage: progressStage,
        video_source: file
          ? AI_SHORTS_CREATION_STATUS_SOURCE.import
          : AI_SHORTS_CREATION_STATUS_SOURCE.url,
      });
      resetWorkflow();
    }
    uploadYoutube.reset();
  };

  // on error to work like close
  useEffect(() => {
    if (progressStage === "error") {
      handleOnClose();
    }
  }, [progressStage]);

  // when generate is clicked we set config in the context and close dialog
  const onGenerate = (shortsGenInfo: AIShortsConfig) => {
    setGenInfo(shortsGenInfo);
    uploadYoutube.resetFields();
    uploadYoutube.setIsGenerating(true);
    onOpenChange?.(false);
  };

  // we show the intro until a file has been selected
  const dialogContent =
    (!uploadYoutube.youtubeVideoId || !uploadYoutube.videoTitle) && !file ? (
      <AIShortsUpload
        isYoutubeUrlLoading={uploadYoutube.isLoading}
        onUrlInputChange={uploadYoutube.handleInputChange}
        onFileSelect={setFile}
      />
    ) : (
      <AIShortsSettings
        youtubeVideoDetails={
          uploadYoutube.youtubeVideoId && uploadYoutube.videoTitle
            ? {
                youtubeVideoId: uploadYoutube.youtubeVideoId,
                title: uploadYoutube.videoTitle,
              }
            : undefined
        }
        file={file}
        onGenerate={onGenerate}
      />
    );

  return (
    <Root {...props}>
      <BaseDialogContent
        onOpenAutoFocus={(event) => {
          // Trap focus without highlighting any particular button
          event.preventDefault();
          const { currentTarget } = event;
          if (currentTarget instanceof HTMLElement) {
            currentTarget.focus();
          }
        }}
        css={{ padding: "71px 85px", boxSizing: "content-box", maxWidth: "fit-content", gap: 16 }}
      >
        <CloseButton onClick={handleOnClose} data-testid="ai-shorts-dialog-close-button" />
        {dialogContent}
      </BaseDialogContent>
    </Root>
  );
}
