import { availableColorSchemeVariantStyles, styled } from "ui";

import { TagLabel } from "~/modules/home/components/ProjectsListItem/ProjectsListItem.styles";

export const AIShortsTagLabel = styled(TagLabel, {
  fontWeight: "600 !important",
  variants: {
    background: {
      niagara: {
        backgroundColor: "$secondary-niagara",
      },
      plum: {
        backgroundColor: "$secondary-plum",
      },
    },
  },
});
AIShortsTagLabel.defaultProps = {
  ...TagLabel.defaultProps,
  color: "grey-100",
};

export const ThumbPlaceholder = styled("div", {
  height: "100%",
  width: "100%",
  backgroundColor: "$dark-100",
});

export const ThumbOverlay = styled("div", {
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "$grey-100",
  zIndex: 1,
  variants: {
    variant: {
      error: {
        backgroundColor: "rgba(255, 0, 0, 0.50)",
      },
      loading: {
        backgroundColor: "$surface-overlay",
      },
      "ads-done": {
        width: "50%",
        height: "50%",
        backgroundColor: "$secondary-niagara",
      },
      "shorts-done": {
        width: "50%",
        height: "50%",
        backgroundColor: "$secondary-plum",
      },
      "ai-edit": {
        width: "50%",
        height: "50%",
        background: "var(--primary-color)",
        padding: "2.5px",
      },
    },
    colorScheme: availableColorSchemeVariantStyles,
  },
});
