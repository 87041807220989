import { styled } from "~/theme";

export const SingleFileDropInputBox = styled("div", {
  alignItems: "center",
  backgroundColor: "$grey-800",
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  gap: 12,
  justifyContent: "center",
  padding: 16,
  variants: {
    draggingOver: {
      true: { border: "1px solid $primary-violet" },
      false: { borderColor: "transparent" },
    },
    fullHeight: {
      true: { height: "100%" },
      false: { height: "auto" },
    },
  },
  defaultVariants: {
    draggingOver: false,
    fullHeight: false,
  },
});

export const InputInstructionContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "$28",
  maxWidth: "273px",
});

export const InputInstructionTextContainer = styled("div", {
  alignItems: "inherit",
  display: "flex",
  flexDirection: "inherit",
  gap: "$12",
  textAlign: "center",
});

export const SingleFileDropInputIconBox = styled("div", {});

export const SingleFileDropInputInvisibleField = styled("input", {
  visibility: "hidden",
  position: "fixed",
  top: 0,
  right: 0,
});

SingleFileDropInputInvisibleField.defaultProps = {
  type: "file",
};
