import { t } from "i18next";
import { useCallback } from "react";

import { useToast } from "~/components/Toast";
import { getVideoFileMetadata } from "~/utils/getVideoFileMetadata";

import {
  VIDEO_ASPECT_RATIO,
  VIDEO_ASPECT_RATIO_MAX_DIFF,
  VIDEO_MAX_DURATION,
  VIDEO_MIN_DURATION,
} from "./constants";

interface AIEditFileValidationProps {
  onFileInvalid: () => void;
  onFileValid: (file: File) => void;
}

export const useAIEditFileValidation = ({
  onFileValid,
  onFileInvalid,
}: AIEditFileValidationProps) => {
  const uploadErrorMessages = {
    VIDEO_DURATION_EXCEEDED: t("projects:ai-edit.errors.video-duration-exceeded"),
    VIDEO_DURATION_SHORT: t("projects:ai-edit.errors.video-duration-short"),
    VIDEO_ASPECT_RATIO_INVALID: t("projects:ai-edit.errors.video-aspect-ratio-invalid"),
  };

  const { add: addToast, clear: clearToasts } = useToast();

  const showError = useCallback(
    (message: string) => {
      addToast(message, {
        severity: "error",
      });
    },
    [addToast]
  );

  const handleError = (error: unknown) => {
    if (error instanceof Error) {
      showError(error.message);
    } else if (typeof error === "string") {
      showError(error);
    }
    onFileInvalid();
  };

  const handleFileChange = async (file?: File) => {
    if (!file) {
      return;
    }
    try {
      const metadata = await getVideoFileMetadata(file);

      if (metadata.duration > VIDEO_MAX_DURATION) {
        throw new Error(uploadErrorMessages.VIDEO_DURATION_EXCEEDED);
      }
      if (metadata.duration < VIDEO_MIN_DURATION) {
        throw new Error(uploadErrorMessages.VIDEO_DURATION_SHORT);
      }
      const fileAspectRatio = metadata.width / metadata.height;
      const aspectRatioDiff = Math.abs(fileAspectRatio - VIDEO_ASPECT_RATIO);
      if (aspectRatioDiff > VIDEO_ASPECT_RATIO_MAX_DIFF) {
        throw new Error(uploadErrorMessages.VIDEO_ASPECT_RATIO_INVALID);
      }
      clearToasts();
      onFileValid(file);
    } catch (error) {
      handleError(error);
    }
  };

  return { handleFileChange };
};
