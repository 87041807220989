import { ProjectFolderType } from "~/database/database.types";
import { pluralize } from "~/utils/pluralize";

export function getHumanFriendlyProjectType(type: ProjectFolderType) {
  if (type === "ai-ads") {
    return "AI Ads";
  } else if (type === "ai-avatar") {
    return "AI Creator";
  } else if (type === "ai-shorts") {
    return "AI Shorts";
  }

  return "";
}

export function getHumanFriendlyProjectTypeItem(type: ProjectFolderType, count: number) {
  if (type === "ai-ads") {
    return pluralize(count, "Ad");
  } else if (type === "ai-avatar") {
    return pluralize(count, "Creator");
  } else if (type === "ai-shorts") {
    return pluralize(count, "Short");
  }

  return "";
}
