import type { IconProps } from "./types";

function PersonAI({ height = "24", width = "24", ...props }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="matrix(-1 0 0 1 4.5 10.55)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="matrix(-1 0 0 1 22.5 10.5)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M19.2822 6.59912C17.6225 4.41362 14.9805 3 12.0047 3C7.59517 3 3.91839 6.10406 3.08496 10.2241M4.72737 17.401C5.23312 18.067 5.8301 18.6613 6.49994 19.1657C6.54191 19.1973 6.58417 19.2286 6.62671 19.2595C8.13313 20.3537 9.99265 21 12.0047 21C16.4173 21 20.096 17.8918 20.9262 13.7677"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.64648 19.2351L5.91643 19.0633C5.84781 19.3549 5.95925 19.6593 6.19988 19.8377L6.64648 19.2351ZM17.3542 19.2351L17.8008 19.8376C18.0414 19.6592 18.1528 19.3548 18.0842 19.0633L17.3542 19.2351ZM7.37654 19.4069C7.87001 17.3102 9.75389 15.75 12.0003 15.75V14.25C9.04223 14.25 6.56577 16.3043 5.91643 19.0633L7.37654 19.4069ZM12.0003 15.75C14.2468 15.75 16.1306 17.3102 16.6241 19.4069L18.0842 19.0633C17.4349 16.3043 14.9584 14.25 12.0003 14.25V15.75ZM16.9076 18.6325C15.5362 19.6491 13.8395 20.25 12.0003 20.25V21.75C14.172 21.75 16.1798 21.0391 17.8008 19.8376L16.9076 18.6325ZM12.0003 20.25C10.1611 20.25 8.46448 19.6491 7.09308 18.6326L6.19988 19.8377C7.82088 21.0391 9.82867 21.75 12.0003 21.75V20.25Z"
        fill="currentColor"
      />
      <circle cx="12" cy="9.5" r="2.5" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
}

export default PersonAI;
