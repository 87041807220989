import { PropsWithChildren, ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";

import { SpokenLanguageField } from "../../../../../project/components/LanguageSelector/SpokenLanguageField";
import { AvailableLanguageOption } from "../../../../../project/services/AvailableTranscriptLanguages";

export interface AIEditLanguageSelectorProps {
  availableLanguages: AvailableLanguageOption[];
  defaultLanguageCode: string;
  onSelectLanguage?: (languageCode: string) => void;
  disabled?: boolean;
}

export function AIEditLanguageSelector({
  availableLanguages,
  defaultLanguageCode,
  onSelectLanguage,
  disabled,
}: PropsWithChildren<AIEditLanguageSelectorProps>): ReactElement {
  const { control, watch } = useForm({
    defaultValues: {
      languageCode: defaultLanguageCode,
    },
  });

  const currentSelectedSpokenLanguage = watch("languageCode");

  useEffect(() => {
    if (currentSelectedSpokenLanguage) {
      onSelectLanguage?.(currentSelectedSpokenLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedSpokenLanguage]);

  return (
    <SpokenLanguageField
      languages={availableLanguages}
      side="top"
      contentCss={{
        maxHeight: "324px",
        overflow: "hidden",
      }}
      {...{ control, disabled, defaultLanguageCode }}
    />
  );
}
