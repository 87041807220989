import { availableColorSchemeVariantStyles, styled } from "~/theme";

export const AIAdsAvatarSelectionCard = styled("div", {
  display: "relative",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  contain: "layout",
  overflow: "hidden",
  border: "4px solid $grey-800",
  borderRadius: "12px",
  "&:hover": {
    borderColor: "$grey-700",
    backgroundColor: "$grey-600",
  },
  variants: {
    selected: {
      true: {
        borderColor: "var(--primary-color)",
        backgroundColor: "$grey-500",
        "&:hover": {
          borderColor: "var(--primary-color)",
          backgroundColor: "$grey-500",
        },
      },
    },
    colorScheme: availableColorSchemeVariantStyles,
    size: {
      fixed: {
        width: "170px",
        height: "260px",
      },
      auto: {
        width: "100%",
        height: "100%",
      },
      small: {
        width: "117px",
        height: "159px",
        borderWidth: "2px",
        boxSizing: "border-box",
        borderRadius: "$16",
      },
    },
  },
  defaultVariants: {
    colorScheme: "purple",
    size: "fixed",
  },
  // The following properties are necessary to prevent a visual glitch where the borders of the
  // image show through the overlay
  perspective: "1000",
  backfaceVisibility: "hidden",
  transform: "translate3d(0,0,0)",
});

export const AIAdsAvatarVideo = styled("video", {
  width: "100%",
  height: "100%",
  minHeight: 0,
  minWidth: 0,
  objectFit: "cover",
  backgroundColor: "$grey-600",
  // The following properties are necessary to prevent a visual glitch where the borders of the
  // image show through the overlay
  borderRadius: "8px",
  perspective: "1000",
  backfaceVisibility: "hidden",
  transform: "translate3d(0,0,0)",
});

export const AIAdsAvatarGradient = styled("div", {
  pointerEvents: "none",
  inset: 0,
  position: "absolute",
  background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.85) 100%)",
  // If this radius isn't slightly smaller than the video's radius, a small section
  // of the video will show through the corners
  borderRadius: "7px",
});
