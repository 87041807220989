import { styled } from "~/theme";

function Import({ ...props }) {
  return (
    <svg
      width="170"
      height="129"
      viewBox="0 0 170 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M159.031 84.3371L162.656 84.3371"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.844 82.5L160.844 86.1735"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.359 124.745L141.359 127.5"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.719 126.122L140 126.122"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169 115.561L165.565 114.162C164.878 113.882 164.334 113.331 164.057 112.635L162.666 109.133L161.275 112.635C160.999 113.331 160.454 113.883 159.767 114.162L156.332 115.561L156.313 115.561L159.747 116.961C160.434 117.241 160.979 117.792 161.255 118.488L162.646 121.99L164.037 118.488C164.314 117.792 164.858 117.24 165.545 116.961L168.98 115.561"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 3.29961H4.74194"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.8706 1.5V5.1"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6931 43.7998V46.4998"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0965 45.1502H11.29"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0001 17.7004L26.4546 16.3288C25.7455 16.0543 25.1833 15.5143 24.898 14.8321L23.462 11.4004L22.026 14.8321C21.7407 15.5143 21.1785 16.0552 20.4694 16.3288L16.9239 17.7004H16.9033L20.4488 19.072C21.1579 19.3465 21.7201 19.8865 22.0054 20.5687L23.4414 24.0004L24.8774 20.5687C25.1627 19.8865 25.7249 19.3456 26.434 19.072L29.9795 17.7004"
        stroke="currentColor"
        strokeWidth="1.39001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="50" y="29.5" width="70" height="70" rx="8" fill="currentColor" fillOpacity="0.12" />
      <path
        d="M79 59.5H78C76.3431 59.5 75 60.8431 75 62.5V66V69.5C75 71.1569 76.3431 72.5 78 72.5H92C93.6569 72.5 95 71.1569 95 69.5V62.5C95 60.8431 93.6569 59.5 92 59.5H91"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M85 56V67.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M81.5 64.5L85 68" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M88.5 64.5L85 68" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export default styled(Import, {
  color: "$primary-violet",
});
