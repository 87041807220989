import { Tabs as CaptionsTabs } from "~/components/Tabs";
import { styled } from "~/theme";

export const AIEditSettingsStepHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "56px",
});

export const AIEditSettingsStepTitle = styled("div", {
  display: "block",
  textAlign: "center",
});

export const AIEditSettingsStepPreviewContent = styled("div", {
  display: "flex",
  columnGap: "24px",
  width: "100%",
  height: "100%",
  minHeight: "100%",
});

export const AIEditSettingsStepSidePanel = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: "4",
  width: "100%",
  justifyContent: "space-between",
});

export const AIEditSettingsStepAIStyleHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginBottom: "12px",
});

export const AIStyleItems = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  justifyContent: "space-between",
  gridGap: "12px",
  paddingBottom: "4px",
  maxHeight: "610px",
  overflow: "auto",
});

// Canvas area, left column
export const AIEditSettingsStepPreviewCanvasContainer = styled("div", {
  borderRadius: "6px",
  flex: "1",
  maxWidth: "416px",
  minWidth: "416px",
  overflow: "hidden",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "minmax(0, 30%) minmax(0, 1fr) minmax(0, 30%)",
});

export const AIEditSettingsStepPreviewCanvas = styled("canvas", {
  objectFit: "contain",
  width: "100%",
  borderRadius: 8,
  gridArea: "1 / 1 / 4 / 2",
  maxWidth: "416px",
});

export const AIEditSettingsStepPreviewMask = styled("div", {
  gridArea: "1 / 1 / 4 / 2",
  borderRadius: 8,
  background:
    "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.25) 25%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 75%, #000000 100%)",
  width: "100%",
  zIndex: 2,
});

export const AIEditSettingsStepPreviewHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginBottom: "12px",
  gridArea: "1 / 1 / 2 / 2",
  padding: "12px",
  zIndex: 3,
});

export const AIEditSettingsStepPreviewTitle = styled("div", {
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "392px",
});

export const AIEditSettingsStepPreviewFooter = styled("div", {
  gridArea: "3 / 1 / 4 / 2",
  zIndex: 3,
  padding: "12px",
  height: "100%",
  width: "100%",
});

export const AIEditSettingsStepPreviewLanguageContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "grid",
  alignItems: "end",
});

export const TabsRoot = styled(CaptionsTabs.Root, {
  width: "100%",
  marginBottom: "$12",
});

export const Tabs = styled(CaptionsTabs.List, {
  height: "calc(var(--sizes-48) + 8px)",
  marginBottom: "$24",
  backgroundColor: "$grey-950",
  padding: "4px",
  borderRadius: "calc(var(--radii-24) + 4px)",
  border: 0,
  width: "300px",
  margin: "0 auto",
});

export const Tab = styled(CaptionsTabs.Trigger, {
  border: 0,
  borderRadius: "var(--radii-24)",
  height: "$48",
  color: "$grey-400",
  "&[data-state=active]": {
    borderColor: "transparent",
    color: "$white",
    background: "$gradient-purple",
  },
});

export const TabContent = styled(CaptionsTabs.Content, {});

export const AIEditStylesEmpty = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gridColumn: "span 3",
});
