import { styled } from "~/theme";

import { Text } from "../../Text";

export const CreatorHubBaseHeader = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 8,
});

export const CreatorHubBaseSubtitle = styled(Text, {
  textAlign: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@bp2": {
    whiteSpace: "normal",
  },
  defaultVariants: {
    color: "grey-500",
    variant: "body-1",
  },
});

export const CreatorHubBaseTitleContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  width: "100%",
  height: "2em",
  gap: 8,
  color: "$grey-200",
  "&>*": {
    flexShrink: 0,
  },
});

export const CreatorHubBaseTitleLabel = styled(Text, {
  textAlign: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  minWidth: 0,
  flexBasis: 0,
  flexGrow: 1,
  maxWidth: "fit-content",
  defaultVariants: {
    color: "$grey-200",
    variant: "heading-3",
  },
});
