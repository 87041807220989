import * as Dialog from "@radix-ui/react-dialog";
import { useContext, useMemo } from "react";

import { PROCEED_WITH_CLICK } from "~/constants/mixpanel.constants";
import { ProjectCreationContext, ProjectCreationSettings } from "~/context/ProjectCreationContext";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useDrawFrameCanvas } from "~/hooks/useDrawFrameCanvas";
import { LanguageSelector } from "~/modules/project/components/LanguageSelector";
import { useAvailableTranscriptLanguages } from "~/modules/project/hooks/useAvailableTranscriptLanguages";
import { useLastUsedLanguages } from "~/modules/project/hooks/useLastUsedLanguages";
import { TranscriptOptions } from "~/modules/project/hooks/useTranscriptGenerator";
import { formatFileSize } from "~/utils/file";
import { removeFileExtension } from "~/utils/removeFileExtension";

import { Text } from "../";
import { BaseDialogContent, CloseButton } from "../BaseDialog";

import { validateTranslationDuration } from "./video-limits";
import {
  VideoUploadProjectSettingsHeader,
  VideoUploadProjectSettingsLanguageContainer,
  VideoUploadProjectSettingsPreviewCanvas,
  VideoUploadProjectSettingsPreviewContainer,
  VideoUploadProjectSettingsPreviewContent,
  VideoUploadProjectSettingsTitle,
} from "./VideoUploadProjectSettings.styles";

const secondsToMinutes = (seconds: number) => Math.trunc(seconds / 60);

const formatDuration = (duration: number | undefined) => {
  if (duration === undefined) {
    return "";
  }
  const minutes = secondsToMinutes(duration);
  const seconds = Math.trunc(duration % 60);

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

interface VideoUploadProps {
  isOpen: boolean;
  onClose: () => void;
}

export function VideoUploadProjectSettings({ isOpen, onClose }: VideoUploadProps) {
  const {
    setProjectCreationSettings,
    finishProjectCreation,
    currentVideoFileMetadata: videoMetadata,
    cancelProjectCreation,
    currentProjectId,
  } = useContext(ProjectCreationContext);

  const { availableLanguages, status: availableLanguagesStatus } =
    useAvailableTranscriptLanguages();
  const analytics = useAnalytics();
  const { handleCanvas, setPreviewCanvas } = useDrawFrameCanvas(videoMetadata);

  const translationLanguages = useMemo(() => {
    return availableLanguages;
  }, [availableLanguages, availableLanguagesStatus]);
  const availableDubbingLanguages = useMemo(() => {
    return translationLanguages.filter((l) => l.isAudioDubbingAvailable);
  }, [translationLanguages, availableLanguagesStatus]);

  const {
    lastLanguageCode,
    lastTranslationLanguageCode,
    lastTranslateAudioEnabled,
    setLastLanguageCode,
    setLastTranslateAudioEnabled,
    setLastTranslationLanguageCode,
  } = useLastUsedLanguages();

  // only allow translation for videos less than or equal 5 minutes
  const enableTranslation =
    !videoMetadata || validateTranslationDuration(videoMetadata.duration ?? 0).isValid;

  const clearStateOnClose = () => {
    finishProjectCreation(); // clears current creation state
    setPreviewCanvas(null);
  };

  const handleOnCreate = (transcriptOptions: TranscriptOptions) => {
    const translationMode = transcriptOptions.translateAudioEnabled
      ? "autodub"
      : (transcriptOptions.targetLanguageCode ?? "none") !== "none"
      ? "captions_only"
      : "none";
    analytics.track(PROCEED_WITH_CLICK, {
      project_id: currentProjectId,
      spoken_language: transcriptOptions.languageCode,
      translation_language: transcriptOptions.targetLanguageCode,
      translation_mode: translationMode,
    });

    const settings: ProjectCreationSettings = {
      spokenLanguageCode: transcriptOptions.languageCode,
      translationLanguageCode: transcriptOptions.targetLanguageCode ?? "none",
      shouldDub:
        transcriptOptions.translateAudioEnabled && transcriptOptions.targetLanguageCode !== "none",
    };

    // setting the project creation settings will allow creation to proceed
    setProjectCreationSettings(settings);

    // remember for next creation
    setLastLanguageCode(settings.spokenLanguageCode);
    setLastTranslationLanguageCode(settings.translationLanguageCode);
    setLastTranslateAudioEnabled(settings.shouldDub);

    // close dialog
    onClose();
    clearStateOnClose();
  };

  const handleOnClose = () => {
    cancelProjectCreation(undefined, true);
    onClose();
    clearStateOnClose();
  };

  return (
    <Dialog.Root open={isOpen}>
      <BaseDialogContent
        onOpenAutoFocus={(event) => {
          event.preventDefault();
        }}
        css={{ boxSizing: "content-box", maxWidth: "650px", overflowX: "hidden" }}
      >
        <CloseButton onClick={handleOnClose} />
        <VideoUploadProjectSettingsHeader>
          <VideoUploadProjectSettingsTitle>
            <Text variant="heading-3" color="grey-200">
              {removeFileExtension(videoMetadata?.name ?? "")}
            </Text>
          </VideoUploadProjectSettingsTitle>
          <div>
            <Text variant="body-2" color="grey-500">
              {videoMetadata
                ? `${formatDuration(videoMetadata?.duration)} · ${videoMetadata?.width ?? 0}x${
                    videoMetadata?.height ?? 0
                  } · ${formatFileSize(videoMetadata?.size)}`
                : ""}
            </Text>
          </div>
        </VideoUploadProjectSettingsHeader>
        <VideoUploadProjectSettingsPreviewContent>
          <VideoUploadProjectSettingsPreviewContainer>
            <VideoUploadProjectSettingsPreviewCanvas ref={handleCanvas} />
          </VideoUploadProjectSettingsPreviewContainer>
          <VideoUploadProjectSettingsLanguageContainer>
            <LanguageSelector
              availableLanguages={availableLanguages}
              availableTranslationLanguages={translationLanguages}
              availableDubbingLanguages={availableDubbingLanguages}
              defaultLanguageCode={lastLanguageCode}
              defaultTranslationLanguageCode={lastTranslationLanguageCode}
              defaultTranslateAudioEnabled={lastTranslateAudioEnabled}
              onSelectLanguage={handleOnCreate}
              enableTranslation={enableTranslation}
              fullWidthSubmit={true}
            />
          </VideoUploadProjectSettingsLanguageContainer>
        </VideoUploadProjectSettingsPreviewContent>
      </BaseDialogContent>
    </Dialog.Root>
  );
}
