import { t } from "i18next";
import type { MouseEvent } from "react";
import { useCallback, useMemo, useState } from "react";
import { Text } from "ui";

import AIShorts from "~/components/icons/AIShorts";
import Delete from "~/components/icons/Delete";
import ErrorCircle from "~/components/icons/ErrorCircle";
import PersonAI from "~/components/icons/PersonAI";
import { Spinner } from "~/components/Spinner";
import { Tooltip } from "~/components/Tooltip";
import { ProjectFolderType } from "~/database/database.types";
import { useDrawFrameCanvas } from "~/hooks/useDrawFrameCanvas";
import {
  FileName,
  ProjectsListItemWrapper,
  Remove,
  ThumbBox,
  ThumbTitleAndDurationContainer,
  TitleAndDurationContainer,
  PreviewCanvas,
} from "~/modules/home/components/ProjectsListItem/ProjectsListItem.styles";
import { getYoutubeThumbnailUrl } from "~/utils/youtube";

import {
  getHumanFriendlyProjectType,
  getHumanFriendlyProjectTypeItem,
} from "../../utils/getHumanFriendlyProjectType";

import { FolderThumbnail } from "./FolderThumbnail";
import { AIShortsTagLabel, ThumbOverlay, ThumbPlaceholder } from "./ProjectFolderListItem.styles";
import type { ProjectFolderListItemProps } from "./ProjectFolderListItem.types";

export function ProjectFolderListItem({
  project,
  onRemove,
  uploadYoutube,
  videoFileMetadata,
}: ProjectFolderListItemProps) {
  const { type, title, status, thumbnailPath } = project;
  const { handleCanvas } = useDrawFrameCanvas(videoFileMetadata);

  const isError = status === "error";
  const isProcessing = status === "processing";
  const isDone = status === "done";

  const subtitle: string = useMemo(() => {
    if (isProcessing) {
      // note that minutesRemaining is currently unused
      return project.processingMessage;
    } else if (isError) {
      return t("projects:project-folder-list-item.subtitle.error", {
        projectType: getHumanFriendlyProjectType(type),
      });
    } else if (isDone) {
      const { numShorts, shortsLength } = project;
      const itemName = getHumanFriendlyProjectTypeItem(type, numShorts);

      if (type === "ai-avatar") {
        return `AI ${itemName}`;
      }

      const subProjectCountString = `${numShorts} ${itemName}`;

      if (type === "ai-shorts") {
        const shortsLengthString =
          typeof shortsLength === "number"
            ? `${shortsLength}s`
            : capitalizeFirstLetter(shortsLength);
        return `${subProjectCountString} · ${shortsLengthString}`;
      } else {
        return subProjectCountString;
      }
    } else {
      console.error(`Unhandled ${type} project status`, status);
      return "";
    }
  }, [isProcessing, type, isError, isDone, project, status]);

  const handleRemove = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      onRemove?.(project.id);
    },
    [project.id, onRemove]
  );

  const [thumbError, setThumbError] = useState(false);
  const hasThumb = (!!thumbnailPath || uploadYoutube?.youtubeVideoId) && !thumbError;
  const hasFirstFrame = !!videoFileMetadata?.canvas;
  const typeDisplayName = getHumanFriendlyProjectType(type);

  return (
    <ProjectsListItemWrapper
      css={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <ThumbTitleAndDurationContainer>
        <ThumbBox>
          {hasThumb ? (
            <FolderThumbnail
              thumbnailPath={
                thumbnailPath || getYoutubeThumbnailUrl(uploadYoutube.youtubeVideoId ?? "")
              }
              title={title}
              onError={() => setThumbError(true)}
            />
          ) : hasFirstFrame ? (
            <PreviewCanvas ref={handleCanvas} />
          ) : (
            <ThumbPlaceholder />
          )}
          {isError && (
            <ThumbOverlay variant="error">
              <ErrorCircle />
            </ThumbOverlay>
          )}
          {isProcessing && (
            <ThumbOverlay variant="loading">
              <Spinner theme="white" inline />
            </ThumbOverlay>
          )}
          {isDone && (
            <ThumbOverlay variant={type === "ai-ads" ? "ads-done" : "shorts-done"}>
              <FolderItemIcon type={type} />
            </ThumbOverlay>
          )}
        </ThumbBox>
        <TitleAndDurationContainer>
          <FileName title={title} translate="no">
            {title}
          </FileName>
          <Text color="grey-500" variant="body-2">
            {subtitle}
          </Text>
        </TitleAndDurationContainer>
      </ThumbTitleAndDurationContainer>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 12 }}>
        {!isError && (
          <AIShortsTagLabel background={type === "ai-ads" ? "niagara" : "plum"}>
            {typeDisplayName}
          </AIShortsTagLabel>
        )}
        <Tooltip tooltipText={`Delete ${typeDisplayName}`}>
          <Remove
            aria-label={`Delete ${typeDisplayName}`}
            data-testid="project-list-delete-short-button"
            onClick={handleRemove}
          >
            <Delete />
          </Remove>
        </Tooltip>
      </div>
    </ProjectsListItemWrapper>
  );
}

function FolderItemIcon({ type }: { type: ProjectFolderType }) {
  return type === "ai-ads" || type === "ai-avatar" ? (
    <PersonAI width={18} height={18} />
  ) : (
    <AIShorts width={18} height={18} />
  );
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
