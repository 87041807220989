import { Fragment } from "react";

import { ThumbImg } from "../ProjectsListItem/ProjectsListItem.styles";

import { ThumbGrid } from "./FolderThumbnail.styles";
import { FolderThumbnailProps } from "./FolderThumbnail.types";

const MAX_SUB_THUMBNAILS = 4;
const OBSCURED_THUMBNAIL_IDX = 2;

export function FolderThumbnail({ thumbnailPath, title, onError }: FolderThumbnailProps) {
  if (typeof thumbnailPath === "string") {
    return <ThumbImg src={thumbnailPath} alt={title} onError={onError} />;
  }
  return (
    <ThumbGrid>
      {thumbnailPath.slice(0, MAX_SUB_THUMBNAILS).map((path, index) => (
        <Fragment key={index}>
          {
            // This ensures that if only 3 thumbnails are available, the third thumbnail is not
            // obscured by the overlay icon
            index === OBSCURED_THUMBNAIL_IDX && thumbnailPath.length === MAX_SUB_THUMBNAILS - 1 && (
              <div></div>
            )
          }
          <ThumbImg src={path} alt={title} onError={onError} />
        </Fragment>
      ))}
    </ThumbGrid>
  );
}
