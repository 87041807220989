import { t } from "i18next";
import { useRef, ChangeEvent, useCallback } from "react";

import { Button } from "~/components";
import { SingleFileDropInputInvisibleField } from "~/components/SingleFileDropInput/SingleFileDropInput.styles";
import { FILE_UPLOAD_CLICK } from "~/constants/mixpanel.constants";
import { useAnalytics } from "~/hooks/useAnalytics";

import * as S from "./AIEditUploadStep.styles";
import type { AIEditUploadProps } from "./AIEditUploadStep.types";
import { AIEditUploadStepBanner } from "./Banner/AIEditUploadStepBanner";

export function AIEditUploadStep({ onFileSelect }: AIEditUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { track } = useAnalytics();

  const handleFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.item(0);
    onFileSelect(file!);
  };

  const onClick = useCallback(() => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.value = "";
    fileInputRef.current.click();
    track(FILE_UPLOAD_CLICK, {
      project_type: "ai_edit",
    });
  }, [fileInputRef]);

  return (
    <>
      <AIEditUploadStepBanner />

      <S.AIEditUploadStepHeader>
        <S.AIEditUploadStepTitle as="h2">{t("projects:ai-edit.title")}</S.AIEditUploadStepTitle>

        <S.AIEditUploadStepSubtitle as="p">
          {t("projects:ai-edit.subtitle")}
        </S.AIEditUploadStepSubtitle>
      </S.AIEditUploadStepHeader>

      <S.AIEditUploadStepButtonContainer>
        <Button
          aria-label="Upload video from file"
          data-testid="ai-edit-dialog-upload-button"
          variant="secondary"
          size="md"
          onClick={onClick}
        >
          {t("projects:ai-edit.upload-btn")}
        </Button>
      </S.AIEditUploadStepButtonContainer>

      <SingleFileDropInputInvisibleField
        ref={fileInputRef}
        onChange={handleFileInput}
        multiple={false}
        accept="video/*"
        data-testid="ai-edit-dialog-invisible-file-input"
      />
    </>
  );
}
