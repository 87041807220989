import { styled } from "~/theme";

import { BaseDialogContent } from "../BaseDialog";

export const CreatorHubDialogContent = styled(BaseDialogContent, {
  gap: "$16 !important",
  maxWidth: "90vw !important",
  maxHeight: "90vh !important",
  width: "822px !important",
  height: "949px !important",
  variants: {
    step: {
      initial: {
        gap: "$24 !important",
        padding: "0 !important",
        width: "885px !important",
        height: "766px !important",
        overflow: "hidden",
        "@bp1": {
          overflow: "auto",
        },
      },
      url: {
        gap: "$24 !important",
        padding: "$24 0 !important",
        width: "885px !important",
        height: "766px !important",
        overflow: "hidden",
      },
      products: {
        padding: "$48 !important",
      },
      info: {
        padding: "$48 !important",
      },
      script: {
        gap: "$24 !important",
      },
      avatar: {
        padding: "$48 $48 $24 $48 !important",
      },
      prompt: {
        gap: "$24 !important",
        height: "auto !important",
      },
      custom: {},
    },
  },
});
