import { Style as AIEditStyle } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { PROCEED_WITH_CLICK } from "~/constants/mixpanel.constants";
import { ProjectCreationContext, ProjectCreationSettings } from "~/context/ProjectCreationContext";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useAIEditStyles } from "~/modules/command-list/hooks/useAIEditStyles";

import { useLastUsedLanguages } from "../../../project/hooks/useLastUsedLanguages";

import type { AIEditCreationStep, AIEditAvailableTag } from "./AIEditDialog.types";
import { useAIEditFileValidation } from "./useAIEditFileValidation";

export function useAIEditDialog() {
  const analytics = useAnalytics();
  const { premium, basic } = useAIEditStyles();

  const {
    startAIEditProjectCreation,
    setProjectCreationSettings,
    finishProjectCreation,
    currentVideoFileMetadata: videoMetadata,
    cancelProjectCreation,
    currentProjectId,
  } = useContext(ProjectCreationContext);

  const [step, setStep] = useState<AIEditCreationStep>("upload");
  const [tag, setTag] = useState<AIEditAvailableTag>("premium");
  const [isOpen, setIsOpen] = useState(false);
  const premiumRef = useRef(premium);
  const basicRef = useRef(basic);

  const openDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setStep("upload");
    const initialStyle = tag === "premium" ? premium?.[0] : basic?.[0];
    if (initialStyle) {
      setCurrentAIEditStyle(initialStyle);
    }
    setIsOpen(false);
  }, [premium, basic, tag]);

  const clearProject = useCallback(() => {
    cancelProjectCreation(undefined, true);
    finishProjectCreation();
    setStep("upload");
  }, [cancelProjectCreation, finishProjectCreation]);

  const handleClose = useCallback(() => {
    closeDialog();
    if (step === "settings") {
      clearProject();
    }
  }, [step, clearProject, closeDialog]);

  // Step 1
  const { handleFileChange } = useAIEditFileValidation({
    onFileValid: (file: File) => {
      setStep("settings");
      startAIEditProjectCreation(file);
    },
    onFileInvalid: () => clearProject(),
  });

  // Step 2: originalLanguage
  const { lastLanguageCode, setLastLanguageCode } = useLastUsedLanguages();
  const [originalLanguage, setOriginalLanguage] = useState(lastLanguageCode);
  const onSelectOriginalLanguage = (language: string) => {
    setOriginalLanguage(language);
  };
  // Step 2: Edit style
  const [currentAIEditStyle, setCurrentAIEditStyle] = useState<AIEditStyle | undefined>(
    premium?.[0]
  );
  const onSelectedAIEditStyle = (aiEditStyle: AIEditStyle) => {
    setCurrentAIEditStyle(aiEditStyle);
  };
  // Step 2: Create Project
  const handleOnCreateProject = (styleId: string) => {
    analytics.track(PROCEED_WITH_CLICK, {
      project_id: currentProjectId,
      spoken_language: originalLanguage,
      // AI Edit currently does not have translation or dubbing
      translation_language: "none",
      translation_mode: "none",
      project_type: "ai_edit",
      style: styleId,
    });

    const settings: ProjectCreationSettings = {
      spokenLanguageCode: originalLanguage,
      translationLanguageCode: "none",
      shouldDub: false,
      aiEdit: {
        styleId,
      },
    };

    // setting the project creation settings will allow creation to proceed
    setProjectCreationSettings(settings);

    // remember for next creation
    setLastLanguageCode(settings.spokenLanguageCode);

    // close dialog
    closeDialog();
    finishProjectCreation();
  };

  // change the selected currentAIEditstyle when changing between premium + basic lists
  useEffect(() => {
    const initialStyle = tag === "premium" ? premiumRef.current?.[0] : basicRef?.current?.[0];
    setCurrentAIEditStyle(initialStyle);
  }, [tag]);

  useEffect(() => {
    premiumRef.current = premium;
    basicRef.current = basic;
  }, [basic, premium]);

  return {
    step,
    isOpen,
    open: openDialog,
    handleClose,

    // Step 1: Upload
    handleFileChange,

    // Step 2: Settings
    videoMetadata,
    currentAIEditStyle,
    onSelectOriginalLanguage,
    onSelectedAIEditStyle,
    handleOnCreateProject,
    tag,
    setTag,
  };
}
