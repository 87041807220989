import { CINEMATIC_PREVIEW_URL } from "../../../../../command-list/services/AIEditStyles/data/cinematic.constants";
import { IMPACT_PREVIEW_URL } from "../../../../../command-list/services/AIEditStyles/data/impact.constants";
import { PAPER_PREVIEW_URL } from "../../../../../command-list/services/AIEditStyles/data/paper.constants";
import { AIStyleItem } from "../../common/AIStyleItem";

import * as S from "./AIEditUploadStepBanner.styles";

export function AIEditUploadStepBanner() {
  return (
    <S.UploadBanner>
      <S.BlurOverlayWrapper>
        <S.BlurOverlay />
      </S.BlurOverlayWrapper>

      <S.AIStyleItems>
        <AIStyleItem previewUrl={CINEMATIC_PREVIEW_URL} autoPlay size="fixed" />

        <AIStyleItem previewUrl={IMPACT_PREVIEW_URL} autoPlay selected size="fixed" />

        <AIStyleItem previewUrl={PAPER_PREVIEW_URL} autoPlay size="fixed" />
      </S.AIStyleItems>
    </S.UploadBanner>
  );
}
