import { useCallback, useRef } from "react";

import { AI_CREATOR_PREVIEW } from "~/constants/mixpanel.constants";
import { useLatestRef } from "~/hooks/helpers";
import { useAnalytics } from "~/hooks/useAnalytics";

import { AITwinName } from "../AITwinName";

import {
  AIAdsAvatarGradient,
  AIAdsAvatarSelectionCard,
  AIAdsAvatarVideo,
} from "./AIAvatarItem.styles";
import { AIAvatarItemProps } from "./AIAvatarItem.types";

export const AVATAR_COLORS = [
  { red: 0, green: 255, blue: 161 },
  { red: 237, green: 166, blue: 255 },
  { red: 202, green: 255, blue: 255 },
  { red: 250, green: 255, blue: 0 },
];

export function AIAvatarItem({
  id,
  type,
  name,
  previewUrl,
  posterUrl,
  color,
  selected,
  colorScheme,
  onClick,
  ignoreAnalytics,
  size,
  disabled,
}: AIAvatarItemProps) {
  const analytics = useAnalytics();

  const videoRef = useRef<HTMLVideoElement>(null);
  const playPromiseRef = useRef<Promise<void> | null>(null);

  const trackPreviewRef = useLatestRef(() => {
    if (!ignoreAnalytics) {
      analytics.track(AI_CREATOR_PREVIEW, {
        creator_id: id,
        creator_name: name,
        creator_type: type,
      });
    }
  });

  const playVideo = useCallback(() => {
    if (!previewUrl || !videoRef.current) {
      return;
    }

    trackPreviewRef.current();
    playPromiseRef.current = videoRef.current.play().catch(console.warn);
  }, [previewUrl, trackPreviewRef]);

  const stopVideo = useCallback(() => {
    if (!previewUrl || !videoRef.current) {
      return;
    }

    if (playPromiseRef.current) {
      playPromiseRef.current.then(() => {
        videoRef.current?.pause();
        videoRef.current?.load();
      });
    } else {
      videoRef.current?.pause();
      videoRef.current?.load();
    }
  }, [previewUrl]);

  return (
    <AIAdsAvatarSelectionCard
      // NOTE: we use enter + leave instead of over + out because the former will only trigger once
      // as the user moves around within the parent container. the latter trigger every time the user
      // moves over a child element.
      onMouseEnter={playVideo}
      onMouseLeave={stopVideo}
      selected={selected}
      colorScheme={colorScheme}
      size={size}
      css={{
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <AIAdsAvatarVideo
        ref={videoRef}
        disablePictureInPicture
        src={previewUrl}
        poster={posterUrl ?? undefined}
        preload={posterUrl ? "none" : "metadata"}
        onClick={disabled ? undefined : onClick}
        muted
        loop
      />
      <AIAdsAvatarGradient />
      <AITwinName
        css={{ position: "absolute", bottom: "8px", left: "8px", right: "8px" }}
        name={name}
        color={color}
        size={size === "small" ? "sm" : undefined}
      />
    </AIAdsAvatarSelectionCard>
  );
}
