import { SingleFileDropInput } from "~/components/SingleFileDropInput";
import { styled } from "~/theme";

export const ProjectsListSingleFileDropInput = styled(SingleFileDropInput, {
  display: "block",
  variants: {
    hide: {
      true: {
        display: "none",
      },
    },
  },
});

export const ProjectsUnorderedList = styled("ul", {
  display: "flex",
  flexDirection: "column",
  gap: 24,
  height: "100%",
  marginTop: "$24",
  variants: {
    hasProjects: {
      false: {
        display: "grid",
        flexGrow: 1,
      },
    },
  },
});

export const ProjectsInDateList = styled("li", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
});

export const ProjectsList = styled("ul", {
  display: "flex",
  flexDirection: "column",
  gap: "$16",
});

export const ProjectsDatesDivider = styled("div", {
  backgroundColor: "$grey-800",
  height: 1,
  marginTop: 4,
  marginBottom: 12,
  width: "100%",
});
