import { useEffect, useState } from "react";

import { Skeleton } from "~/components/Skeleton";

import * as S from "./HomeLoading.styles";
import { opacityTransitionDuration } from "./HomeLoading.styles";

function HomeLoading({ isLoading }: { isLoading: boolean }) {
  const [render, setRender] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      const timeout = setTimeout(() => {
        setRender(false);
      }, opacityTransitionDuration);
      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  if (!render) {
    return null;
  }

  return (
    <S.HomeLoadingContainer isLoading={isLoading}>
      <S.SkeletonGroup>
        <Skeleton height="24px" width="200px" />
        <Skeleton height="78px" />
        <Skeleton height="78px" />
        <Skeleton height="78px" />
      </S.SkeletonGroup>
      <S.SkeletonGroup>
        <Skeleton height="24px" width="200px" />
        <Skeleton height="78px" />
        <Skeleton height="78px" />
        <Skeleton height="78px" />
      </S.SkeletonGroup>
      <S.SkeletonGroup>
        <Skeleton height="24px" width="200px" />
        <Skeleton height="78px" />
        <Skeleton height="78px" />
        <Skeleton height="78px" />
      </S.SkeletonGroup>
    </S.HomeLoadingContainer>
  );
}

export default HomeLoading;
