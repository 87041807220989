import {
  CreatorHubBannerSection,
  CreatorHubBannerVideo,
  CreatorHubBannerBlurOverlay,
} from "./CreatorHubBanner.styles";

const CREATOR_HUB_DIALOG_VIDEO = "https://captions-cdn.xyz/desktop-site/creator-hub-dialog.webm";

export function CreatorHubBanner() {
  return (
    <CreatorHubBannerSection>
      <CreatorHubBannerBlurOverlay />
      <CreatorHubBannerVideo loop muted autoPlay disablePictureInPicture controls={false}>
        <source type="video/webm" src={CREATOR_HUB_DIALOG_VIDEO} />
      </CreatorHubBannerVideo>
    </CreatorHubBannerSection>
  );
}
