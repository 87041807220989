import { useEffect, useState } from "react";

import { useImageGenerator } from "~/context";
import { useDrawFrameCanvas } from "~/hooks/useDrawFrameCanvas";

import { ThumbPlaceholder } from "../ProjectFolderListItem/ProjectFolderListItem.styles";
import * as S from "../ProjectsListItem/ProjectsListItem.styles";

import { ProjectListItemThumbnailProps } from "./ProjectListItemThumbnail.types";

function ProjectListItemThumbnail({
  title,
  thumbnailPath,
  videoFileMetadata,
}: Readonly<ProjectListItemThumbnailProps>) {
  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
  const imageGenerator = useImageGenerator();
  const { handleCanvas } = useDrawFrameCanvas(videoFileMetadata);

  useEffect(() => {
    const getThumbnailUrl = async () => {
      if (thumbnailPath) {
        const thumbnailUrl = await imageGenerator.getImageUrl(thumbnailPath);
        setThumbnailUrl(thumbnailUrl);
      }
    };
    getThumbnailUrl();
  }, [thumbnailPath]);

  const [thumbError, setThumbError] = useState(false);
  const hasThumb = thumbnailUrl !== "" && !thumbError;
  const hasFirstFrame = !!videoFileMetadata?.canvas;

  return (
    <>
      {hasThumb ? (
        <S.ThumbImg src={thumbnailUrl} alt={title} onError={() => setThumbError(true)} />
      ) : hasFirstFrame ? (
        <S.PreviewCanvas ref={handleCanvas} />
      ) : (
        <ThumbPlaceholder />
      )}
    </>
  );
}

export default ProjectListItemThumbnail;
