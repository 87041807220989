import { useCallback, useEffect, useState } from "react";

import { VideoFileThumbnailResult } from "~/utils/getVideoFileThumbnailAtTime";

export interface ImageSource {
  image: ImageBitmap;
  width: number;
  height: number;
}

export type CanvasImageFrameSource = VideoFileThumbnailResult | ImageSource;

function isImageSource(source: CanvasImageFrameSource): source is ImageSource {
  return "image" in source;
}

export const useDrawFrameCanvas = (source: CanvasImageFrameSource | null | undefined) => {
  const [previewCanvas, setPreviewCanvas] = useState<HTMLCanvasElement | null>(null);

  const handleCanvas = useCallback((node: HTMLCanvasElement) => {
    setPreviewCanvas(node);
  }, []);

  useEffect(() => {
    if (!source || !previewCanvas) {
      return;
    }

    previewCanvas.width = source.width;
    previewCanvas.height = source.height;
    const ctx = previewCanvas.getContext("2d");

    if (!ctx) {
      return;
    }

    if (isImageSource(source)) {
      ctx.drawImage(source.image, 0, 0, source.width, source.height);
    } else if (source.canvas) {
      ctx.drawImage(source.canvas, 0, 0, source.width, source.height);
    }
  }, [source, previewCanvas]);

  return {
    handleCanvas,
    setPreviewCanvas,
  };
};
