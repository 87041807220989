import { Text } from "~/components";
import { keyframes, styled } from "~/theme";

export const ProjectsListItemWrapper = styled("li", {
  alignItems: "center",
  backgroundColor: "$grey-900",
  borderRadius: "$12",
  display: "grid",
  gap: "$24",
  gridTemplateColumns: "minmax(200px, 3fr) 2fr 2fr 2fr auto",
  padding: "$12",
  variants: {
    inCreation: {
      true: {
        backgroundColor: "color-mix(in srgb, $grey-900, transparent 50%)",
        cursor: "wait",
      },
    },
    isAdsInCreation: {
      true: {
        gridTemplateColumns: "minmax(200px, 2fr) auto",
      },
    },
  },
  "&:hover": {
    backgroundColor: "$grey-800",
  },
});

export const ThumbTitleAndDurationContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  minWidth: "0",
  gap: 12,
  flexGrow: "1",
});

export const TitleAndDurationContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  minWidth: "0",
  flexGrow: "1",
  flexBasis: "0",
});

export const LabelAndDeleteContainer = styled("div", {
  alignItems: "center",
  justifyContent: "flex-end",
  display: "flex",
  gap: 12,
  minWidth: 145, // ensure other columns stay centered
});

export const FileName = styled(Text, {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  width: "100%",
  maxWidth: "830px",
  defaultVariants: {
    color: "grey-100",
    variant: "body-1",
  },
});

export const ThumbBox = styled("div", {
  alignItems: "center",
  backgroundColor: "$dark-90",
  borderRadius: 8,
  display: "flex",
  height: 54,
  minWidth: 54,
  overflow: "hidden",
  position: "relative",
  width: 54,
});

export const ThumbImg = styled("img", {
  height: "100%",
  objectFit: "cover",
  width: "100%",
});

export const PreviewCanvas = styled("canvas", {
  minWidth: "56px",
  minHeight: "56px",
  objectFit: "cover",
});

const shine = keyframes({
  to: {
    backgroundPositionX: "-200%",
  },
});

export const ThumbImgAnimatedSkeleton = styled("span", {
  backgroundColor: "$grey-900",
  backgroundImage:
    "linear-gradient(110deg, $grey-700 8%, $grey-800 18%, $grey-900 33%, $grey-800 58%, $grey-700 100%)",
  backgroundSize: "200% 100%",
  width: "100%",
  height: "100%",
  animation: `3s ${shine} ease-in-out 0s infinite`,
});

export const Remove = styled("button", {
  display: "flex",
  color: "$grey-500",
  padding: "8px",
  borderRadius: "6px",
  transition: "color 150ms",
  "&:active, &:focus-visible": {
    backgroundColor: "$colors$primary-purple",
    color: "$grey-200",
  },
  "&:hover": {
    color: "$grey-200",
  },
});

export const TagLabel = styled(Text, {
  backgroundColor: "$grey-700",
  borderRadius: 6,
  padding: "4px 8px",
  whiteSpace: "nowrap",
  textTransform: "uppercase",
  lineHeight: "200% !important",
  defaultVariants: {
    color: "grey-300",
    weight: "600 !important",
    variant: "body-4",
  },
});
