import { Project } from "~/database/database.types";

import { ProjectFolderWithStatus } from "../components/ProjectFolderListItem/ProjectFolderListItem.types";

export function isFolderProject(
  project: Project | ProjectFolderWithStatus
): project is ProjectFolderWithStatus {
  return (
    "type" in project &&
    (project.type === "ai-shorts" || project.type === "ai-ads" || project.type === "ai-avatar")
  );
}
