import { t } from "i18next";

import { Button, Text } from "~/components";
import { queryClient } from "~/context/QueryContext";
import { useOnUnmountEffect } from "~/hooks/helpers";
import { useDrawFrameCanvas } from "~/hooks/useDrawFrameCanvas";
import { useAIEditStyles } from "~/modules/command-list/hooks/useAIEditStyles";
import { formatFileSize } from "~/utils/file";
import { removeFileExtension } from "~/utils/removeFileExtension";

import { useAvailableTranscriptLanguages } from "../../../../project/hooks/useAvailableTranscriptLanguages";
import { useLastUsedLanguages } from "../../../../project/hooks/useLastUsedLanguages";
import type { AIEditAvailableTag, AIEditState } from "../AIEditDialog.types";
import { AIStyleItem } from "../common/AIStyleItem";
import { useAIEditDialog } from "../useAIEditDialog";

import { Tabs, Tab, TabsRoot } from "./AIEditSettingsStep.styles";
import * as S from "./AIEditSettingsStep.styles";
import { AIEditLanguageSelector } from "./LanguageSelector/AIEditLanguageSelector";

function secondsToMinutes(seconds: number) {
  return Math.trunc(seconds / 60);
}

function formatDuration(duration: number | undefined) {
  if (duration === undefined) {
    return "";
  }
  const minutes = secondsToMinutes(duration);
  const seconds = Math.trunc(duration % 60);

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
}

type AIEditSettingsStepProps = {
  videoMetadata: AIEditState["videoMetadata"];
  currentAIEditStyle: AIEditState["currentAIEditStyle"];
  onSelectedAIEditStyle: AIEditState["onSelectedAIEditStyle"];
  onSelectOriginalLanguage: AIEditState["onSelectOriginalLanguage"];
  handleOnCreateProject: AIEditState["handleOnCreateProject"];
};

export function AIEditSettingsStep({
  videoMetadata,
  currentAIEditStyle,
  onSelectedAIEditStyle,
  onSelectOriginalLanguage,
  handleOnCreateProject,
}: AIEditSettingsStepProps) {
  const { premium, basic, isLoading } = useAIEditStyles();
  const { tag, setTag } = useAIEditDialog();

  const { handleCanvas } = useDrawFrameCanvas(videoMetadata);

  const { lastLanguageCode } = useLastUsedLanguages();
  const { availableLanguages } = useAvailableTranscriptLanguages();

  useOnUnmountEffect(() => {
    for (const aiEditStyle of aiEditStyles) {
      const queryKey = ["thumbnail", aiEditStyle.previewUrl];
      const thumbnailUrl = queryClient.getQueryData<string>(queryKey);

      if (thumbnailUrl?.startsWith("blob:")) {
        URL.revokeObjectURL(thumbnailUrl);
        queryClient.invalidateQueries({ queryKey });
      }
    }
  });

  const videoSize = videoMetadata ? formatFileSize(videoMetadata.size) : "";
  const videoDuration = formatDuration(videoMetadata?.duration);
  const videoWidth = videoMetadata?.width ?? 0;
  const videoHeight = videoMetadata?.height ?? 0;

  const aiEditStyles = (tag === "basic" ? basic : premium) || [];

  return (
    <>
      <S.AIEditSettingsStepHeader>
        <S.AIEditSettingsStepTitle>
          <Text variant="heading-3" color="grey-200">
            {t("projects:ai-edit.select-edit-style")}
          </Text>
        </S.AIEditSettingsStepTitle>

        <div>
          <Text variant="body-2" color="grey-500">
            {t("projects:ai-edit.confirm-language-choose-style")}
          </Text>
        </div>
      </S.AIEditSettingsStepHeader>

      <S.AIEditSettingsStepPreviewContent>
        <S.AIEditSettingsStepPreviewCanvasContainer>
          <S.AIEditSettingsStepPreviewHeader>
            <S.AIEditSettingsStepPreviewTitle>
              <Text variant="heading-3" color="grey-200">
                {removeFileExtension(videoMetadata?.name ?? "")}
              </Text>
            </S.AIEditSettingsStepPreviewTitle>

            <div>
              <Text variant="body-2" color="grey-500">
                {videoMetadata
                  ? `${videoDuration} · ${videoWidth}x${videoHeight} · ${videoSize}`
                  : ""}
              </Text>
            </div>
          </S.AIEditSettingsStepPreviewHeader>
          <S.AIEditSettingsStepPreviewMask />
          <S.AIEditSettingsStepPreviewCanvas ref={handleCanvas} />
          <S.AIEditSettingsStepPreviewFooter>
            <S.AIEditSettingsStepPreviewLanguageContainer>
              <div>
                <AIEditLanguageSelector
                  defaultLanguageCode={lastLanguageCode}
                  availableLanguages={availableLanguages}
                  onSelectLanguage={onSelectOriginalLanguage}
                />
              </div>
            </S.AIEditSettingsStepPreviewLanguageContainer>
          </S.AIEditSettingsStepPreviewFooter>
        </S.AIEditSettingsStepPreviewCanvasContainer>

        <S.AIEditSettingsStepSidePanel>
          <TabsRoot value={tag} onValueChange={(nextTag) => setTag(nextTag as AIEditAvailableTag)}>
            <Tabs>
              <Tab value="premium">{t("projects:ai-edit.premium")}</Tab>
              <Tab value="basic">{t("projects:ai-edit.basic")}</Tab>
            </Tabs>
          </TabsRoot>
          <S.AIStyleItems>
            {aiEditStyles.map((aiEditStyle) => (
              <AIStyleItem
                key={aiEditStyle.id}
                name={aiEditStyle.displayName}
                selected={currentAIEditStyle?.id === aiEditStyle.id}
                autoPlay={currentAIEditStyle?.id === aiEditStyle.id}
                previewUrl={aiEditStyle.previewUrl}
                hlsPreviewUrl={aiEditStyle.hlsPreviewUrl}
                thumbnailUrl={aiEditStyle.thumbnailUrl}
                onClick={() => onSelectedAIEditStyle(aiEditStyle)}
              />
            ))}
            {!isLoading && aiEditStyles.length == 0 && (
              <S.AIEditStylesEmpty>
                <div>{t("projects:ai-edit.no-styles-available")}</div>
              </S.AIEditStylesEmpty>
            )}
          </S.AIStyleItems>
          <Button
            fullWidth
            onClick={() => currentAIEditStyle?.id && handleOnCreateProject(currentAIEditStyle?.id)}
            disabled={!currentAIEditStyle?.id}
          >
            {t("projects:ai-edit.create-btn")}
          </Button>
        </S.AIEditSettingsStepSidePanel>
      </S.AIEditSettingsStepPreviewContent>
    </>
  );
}
