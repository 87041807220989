import { styled } from "~/theme";

function FolderAdd({ ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 9.75V9C21 7.34315 19.6569 6 18 6H11.4142C11.149 6 10.8946 5.89464 10.7071 5.70711L9.58579 4.58579C9.21071 4.21071 8.70201 4 8.17157 4H6C4.34315 4 3 5.34315 3 7V16.5C3 18.1569 4.34315 19.5 6 19.5H13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M16.75 17H22.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M19.75 20L19.75 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}

export default styled(FolderAdd, {
  color: "$white",
  width: 24,
  height: 24,
});
