import { CreatorHubBanner } from "../CreatorHubBanner/CreatorHubBanner";

import {
  CreatorHubBaseHeader,
  CreatorHubBaseSubtitle,
  CreatorHubBaseTitleContainer,
  CreatorHubBaseTitleLabel,
} from "./CreatorHubBase.style";
import { CreatorHubBaseProps } from "./CreatorHubBase.type";

export function CreatorHubBase({ title, description }: CreatorHubBaseProps) {
  return (
    <>
      <CreatorHubBanner />

      <CreatorHubBaseHeader>
        <CreatorHubBaseTitleContainer>
          <CreatorHubBaseTitleLabel>{title}</CreatorHubBaseTitleLabel>
        </CreatorHubBaseTitleContainer>
        <CreatorHubBaseSubtitle translate="no">{description}</CreatorHubBaseSubtitle>
      </CreatorHubBaseHeader>
    </>
  );
}
