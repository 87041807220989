import {
  listAiCreatorsParamsSchema,
  listAiCreatorsResponseSchema,
} from "~/modules/project/services/AIAvatar/AIAvatarGetCreators";
import { createUseQueryHookWithParams } from "~/utils/network/query-helpers";

const useListCreatorsQuery = createUseQueryHookWithParams({
  queryKey: ["creators"],
  path: "ai-avatar/creators",
  responseSchema: listAiCreatorsResponseSchema,
  paramsSchema: listAiCreatorsParamsSchema,
  transformer(response) {
    return response.creators.flatMap((creator) => {
      // TODO: it'd be nice in the future to kill the whole avatar stuff and just use the creators directly
      const avatarsFromVariants = creator.variants.map((variant) => ({
        id: creator.id,
        name: creator.name,
        previewUrl: variant.videoUrl ?? variant.thumbnails[0].url,
        costPerAd: creator.price ?? 0,
        thumbnails: variant.thumbnails,
        variantId: variant.id,
        colorHex: creator.colorHex,
        type: creator.type,
      }));

      return avatarsFromVariants;
    });
  },
  analytics: {
    level: "custom",
    paramsSelector: (params) => ({ filter: params.filter }),
    responseSelector: (response) => ({ count: response.creators.length }),
  },
});

// bridge for backwards compatibility
export function useCreators() {
  return useListCreatorsQuery({
    filter: {
      type: "studio",
      videoType: "ai_creator",
    },
  });
}
