import { t } from "i18next";
import { useMemo } from "react";

import { getLanguageOptionSearchScore } from "~/modules/project/components/LanguageSelector";
import { LanguageItemLabel } from "~/modules/project/components/LanguageSelector/LanguageItemLabel";
import {
  getLocalizedLanguageLabel,
  getLocalizedLanguageName,
} from "~/modules/project/utils/getLocalizedLanguageName";

import { AI_CREATOR_SCRIPT_MAX_LENGTH } from "../../AIAdsCreationDialog";
import { AIAdsDialogTitle, AIAdsPageIndicator } from "../../AIAdsCreationDialog/common";
import { CharacterCounter } from "../../CharacterCounter";
import {
  ComboBox as CreatorHubCustomScriptLanguageSelector,
  ComboBoxGroup,
  ComboBoxOption,
} from "../../ComboBox";
import Language from "../../icons/Language";

import {
  CreatorHubCustomScriptActions,
  CreatorHubCustomScriptContainer,
  CreatorHubCustomScriptHeader,
  CreatorHubCustomScriptInput,
} from "./CreatorHubCustomScript.styles";
import { CreatorHubCustomScriptProps } from "./CreatorHubCustomScript.types";

export function CreatorHubCustomScript({
  availableLanguages,
  languageCode,
  onChangeLanguageCode,
  customScript,
  onChangeCustomScript,
  onBack,
  onNext,
}: CreatorHubCustomScriptProps) {
  const isAvailableLanguageListLoading = availableLanguages.length === 0;

  const normalizedLanguageOptions: (ComboBoxOption | ComboBoxGroup)[] = useMemo(() => {
    return [
      {
        label: "Languages",
        options: availableLanguages
          .filter((item) => item.code !== languageCode)
          .map((item) => ({
            label: (
              <LanguageItemLabel
                displayName={item.display}
                localizedDisplayName={getLocalizedLanguageName(item)}
              />
            ),
            value: item.code,
            getSearchScore: (searchQuery) => getLanguageOptionSearchScore(item, searchQuery),
          })),
      },
    ];
  }, [languageCode, availableLanguages]);

  const customValue = (value?: string) => {
    const language = availableLanguages.find((item) => item.code === value);

    // Should never happen; fallback to default Radix Select behavior
    if (!language) {
      return undefined;
    }

    return getLocalizedLanguageLabel(language);
  };

  const isNextDisabled = !customScript;

  return (
    <>
      <CreatorHubCustomScriptHeader>
        <AIAdsDialogTitle title={t("projects:ai-creators.custom-prompt.title")} readOnly={true} />
        <AIAdsPageIndicator activeStep={1} stepsCount={2} />
      </CreatorHubCustomScriptHeader>

      <CreatorHubCustomScriptContainer>
        <CreatorHubCustomScriptLanguageSelector
          content={{
            align: "end",
            width: "100%",
          }}
          options={normalizedLanguageOptions}
          disabled={isAvailableLanguageListLoading}
          value={languageCode}
          customValue={customValue}
          onValueChange={(value) => onChangeLanguageCode(value)}
          searchPlaceholder={t("common:language-selector.search-placeholder")}
          placeholder={t("common:language-selector.placeholder")}
          triggerIcon={<Language />}
        />

        <CreatorHubCustomScriptInput
          maxLength={AI_CREATOR_SCRIPT_MAX_LENGTH}
          value={customScript}
          placeholder={t("projects:ai-creators.custom-prompt.script-prompt-placeholder")}
          onChange={(ev) => onChangeCustomScript(ev.target.value)}
        />
        <CharacterCounter maxLength={AI_CREATOR_SCRIPT_MAX_LENGTH} value={customScript ?? ""} />
      </CreatorHubCustomScriptContainer>
      <CreatorHubCustomScriptActions
        onNext={onNext}
        onBack={onBack}
        isNextDisabled={isNextDisabled}
        nextLabel={t("projects:ai-creators.custom-prompt.next-step-label")}
      />
    </>
  );
}
