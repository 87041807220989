import { useState } from "react";

import { DropZoneProps } from "../DropZone/DropZone.types";

/**
 * Non-styled area for dropping files.
 *
 * @param className - the CSS class name to assign to the component.
 * @param acceptMultiple - whether the drop area should accept multiple files
 * or not.
 * @param onDrop - callback that will be called when files are dropped into
 * the area.
 * @param children - should render the contents of the area, receiving a flag
 * indicating whether files are being dragged on top of the area or not.
 */
export function DropZone({
  className = "",
  acceptMultiple = true,
  onDrop,
  children,
}: DropZoneProps) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDraggingOver(false);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (acceptMultiple || event.dataTransfer.items.length === 1) {
      event.dataTransfer.dropEffect = "copy";
      setIsDraggingOver(true);
    } else {
      event.dataTransfer.dropEffect = "none";
      setIsDraggingOver(false);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setIsDraggingOver(false);

    if (acceptMultiple || event.dataTransfer.items.length === 1) {
      const files = Array.from(event.dataTransfer.files);
      onDrop && onDrop(files);
    }
  };

  return (
    <div
      className={className}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={handleDrop}
    >
      {children && children(isDraggingOver)}
    </div>
  );
}
