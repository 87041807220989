import { styled } from "~/theme";

export const UploadBanner = styled("div", {
  height: "100%",
  maxHeight: `calc(100%)`,
  position: "relative",
});

export const BlurOverlayWrapper = styled("div", {
  position: "absolute",
  height: "100%",
  width: "100%",
  borderRadius: "50%",
  padding: "72px 32px",
});

const cyanBlueGradient =
  "linear-gradient(180deg, #15FDE1 0%, #5C41FF 100%), linear-gradient(90deg, #5C42FF 0%, #16FDE2 100%), #CED0D7";

export const BlurOverlay = styled("div", {
  position: "relative",
  height: "100%",
  background: cyanBlueGradient,
  opacity: "0.15",
  filter: "blur(52px)",
});

export const AIStyleItems = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "22px",
  padding: "0 70px",

  "> div:nth-child(1), > div:nth-child(3)": {
    width: "208px",
    height: "366px",
    borderRadius: "12px",
    border: "none",
  },

  // Highlighted Item
  "> div:nth-child(2)": {
    width: "226px",
    height: "445px",
    padding: "6.5px",
    borderRadius: "36px",
    borderWidth: "5.5px",
    "> video": {
      borderRadius: "24px",
    },
  },
});
