import { format as _format, type FormatOptions, Locale as DateFNSLocale } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";
import { Locale } from "i18n/locales/locales.types";
import i18n from "i18next";

const locales: Record<Locale, DateFNSLocale> = {
  en: enUS,
  pt: ptBR,
};

type LocaleFormatStr = {
  pt?: string;
};

/**
 * Formats a date according to the specified format string and locale.
 *
 * This function is a wrapper around `date-fns`'s `format` function, with added locale support based
 * on the application's current language.
 *
 * @param date      The date to format. Can be a Date object, timestamp, or string.
 * @param formatStr The format string used to format the date.
 * @param options   Optional formatting options or a locale-specific format string.
 * @returns         The formatted date string.
 *
 * @see {@link https://date-fns.org/v3.6.0/docs/format | date-fns format documentation} for more details on the underlying function.
 */
export function format(
  date: Date | number | string,
  formatStr: string,
  options?: FormatOptions | LocaleFormatStr
) {
  const currentLocale = i18n.resolvedLanguage as keyof LocaleFormatStr;
  const locale = locales[currentLocale] || locales.en;

  if ((options as LocaleFormatStr)?.[currentLocale]) {
    formatStr = (options as LocaleFormatStr)[currentLocale]!;
  }

  return _format(date, formatStr, { locale, ...options });
}
