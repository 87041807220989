import { z } from "zod";

const thumbnailSchema = z.object({
  width: z.number(),
  height: z.number(),
  url: z.string(),
});

const angleSchema = z.object({
  id: z.string(),
  previewUrl: z.string(),
  thumbnails: z.array(thumbnailSchema),
});

const variantSchema = z.object({
  id: z.string(),
  videoUrl: z.string().optional(),
  thumbnails: z.array(thumbnailSchema),
  angles: z.record(z.string(), angleSchema).optional(),
});

export const aiCreatorItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  bio: z.string().optional(),
  colorHex: z.string().optional(),
  price: z.number().optional(),
  custom: z.boolean().optional().default(false),
  type: z.enum(["studio", "ugc"]), // Assuming these are the possible values for AIAvatarJobType
  defaultVariant: variantSchema,
  variants: z.array(variantSchema),
  tags: z.array(z.string()).optional(),
});

export const listAiCreatorsResponseSchema = z.object({
  creators: z.array(aiCreatorItemSchema),
});

export type AICreatorItem = z.infer<typeof aiCreatorItemSchema>;

export const listAiCreatorsParamsSchema = z.object({
  filter: z.object({
    type: z.enum(["studio"]),
    videoType: z.enum(["ai_creator"]),
  }),
});
